import { Component } from 'react'
import {InputAdornment, TextField, StandardTextFieldProps, IconButton, Dialog} from "@material-ui/core";
import {Map as MapIcon} from "@material-ui/icons";
import { Autocomplete } from '@material-ui/lab';
import { GoogleMap, Marker, InfoWindow, InfoBox } from '@react-google-maps/api';
import { parseAsync } from '@babel/core';


interface IProps extends StandardTextFieldProps {
    /**input label */
    label: string;
    /**location name */
    title?: string;
    long?: number;
    lat?: number;
    place_id?: string; 
    
    onSelected: (title:string, lat:number|null, long:number|null, place_id:string|null)=>void;
}

interface IState {
    mapsToken: google.maps.places.AutocompleteSessionToken; 
    options:Option[];
    open:boolean;
    place_id?:string;
    title?:string;
    lat?:number;
    long?:number;
    popupMap:google.maps.Map<Element>|null;
}

interface Option {
    title: string;
    place_id?:string;
    types:string[];
}

export default class LocationInput extends Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            mapsToken: new google.maps.places.AutocompleteSessionToken(),
            options:[],
            open:false,  
            place_id: props.place_id,
            title:props.title,
            lat:props.lat,
            long:props.long,
            popupMap: null
        }
    }

    debounceTimer?: NodeJS.Timeout;
    latestOptions: Option[] = [];

    debounce = (f:()=>void, timeout:number) => {
        if(this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(f, timeout);
    }

    handleInputChange = (event: object, value: string, reason: string) => {
        if (reason ==="input") {
            this.debounce( ()=>this.searchAddress(event, value, reason), 300 );
            this.setState({ title:value, place_id:undefined, lat:undefined, long:undefined});
            if(this.props.onSelected) {
                this.props.onSelected(value, null, null, null);
            }            
        } else {
            this.setState({ title:value});   
        }
    }

    searchAddress = (event: object, value: string, reason: string) =>{
        var autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ 
            input: value,     
            sessionToken: this.state.mapsToken
        }, (predictions, status) => {
          this.latestOptions = predictions? predictions.map(
                (f: any) => { return {
                title:`${f.description}`,
                place_id:f.place_id,
                types:f.types
            }}):[];
            this.updateOptions();
        });
        this.updateOptions();

    }
    
    updateOptions= ()=> {
        let options = this.latestOptions;
        this.setState({ options: options });
    }
  
    showMap = () => {
        this.setState({open:true});
    };
    
    handleClose = () => {
        this.setState({open:false});
    };

    onLoad = (map:google.maps.Map<Element>) => {
        this.setState({popupMap:map});
    }
    
    onUnmount = (map:google.maps.Map<Element>)=> {
        this.setState({popupMap:null});
    }
    
    markerClicked = (e:google.maps.MapMouseEvent)=> {
        window.open(`https://www.google.com/maps/place?q=place_id:${this.state.place_id}` )
    }

    placeSelected = (event: object, place: Option|string|null, reason: string)=>{
        if(!(typeof place  === 'string')) {
            var option = place as Option;
            if(option?.place_id) {
                var service = new google.maps.places.PlacesService(document.createElement('div'));

                service.getDetails({
                    placeId: option.place_id
                }, (result, status) =>{
                    if (status != google.maps.places.PlacesServiceStatus.OK) {
                        console.log(status);
                        return;
                    }
                
                    let location = result?.geometry?.location
                
                    var s = {
                        title: option.title,
                        place_id: option.place_id,
                        lat: location?.lat(),
                        long: location?.lng()
                    }
                    this.setState(s);
                    this.props.onSelected(s.title, s.lat||null, s.long||null, s.place_id||null);
                });
            }
        }
    }

    isOptionSelected = (place:Option, value:Option) => {
        return place.place_id === value.place_id;
    }

    render() {
        const containerStyle = {
            width: '400px',
            height: '400px'
          };
          
          const center = this.state.lat && this.state.long ?
          {
            lat: this.state.lat,
            lng: this.state.long
          }: undefined;

        return (
            <>
                <Dialog aria-labelledby="simple-dialog-title" onClose={this.handleClose} open={this.state.open}>
                    <GoogleMap mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15}
                                onLoad={this.onLoad}
                                onUnmount={this.onUnmount} >
                        { center&& 
                            <Marker position={center} onClick={this.markerClicked}>
                                <InfoWindow position={center}><div>{this.state.title}</div></InfoWindow>
                            </Marker>
                        }
                    </GoogleMap>
                </Dialog>
                
                <Autocomplete 
                    style={{display:"grid", margin:8}}
                    freeSolo        
                    value={{title:this.state.title||"", place_id:this.state.place_id, types:[]}}
                    onChange={(event, value, reason) => this.placeSelected(event, value, reason)}
                    onInputChange={(event, value, reason) => this.handleInputChange(event, value, reason)}
                    getOptionLabel={(option) => option.title}
                    options={this.state.options}
                    getOptionSelected = {(option,value)=> this.isOptionSelected(option, value)}
                    disableClearable
                    renderInput={(params) => (
                        <TextField {...params}
                                label={this.props.label}
                                margin="normal"
                                InputProps={{ ...params.InputProps, ...this.props.InputProps,
                                    type: 'search',
                                    endAdornment: this.state.place_id &&
                                        <InputAdornment position="end">
                                            <IconButton style={{backgroundColor:"transparent"}}  hidden ={!!this.state.place_id} 
                                                        onClick={this.showMap} 
                                                        disabled={!this.state.place_id}>  
                                                <MapIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                  }}
                        
                        />
                    )}
                />
            </>
        )
    }
}
