import { Pairing } from "../models/Pairing";
import { Player } from "../algorithms/managers/ICompetitionManager";
import { TableCell, TableCellBaseProps, TableRow } from "@material-ui/core";
import React, { ReactNode } from "react";
import { isElementOfType } from "react-dom/test-utils";
import { isForOfStatement } from "typescript";

export class Result {
    constructor (
      public points:string, 
      public otherPoints:string, 
      public wins:string) {
    }
  }

export interface ICellProps
{
    key:string;
    className?:string;
    component?:React.ElementType<TableCellBaseProps>;
    scope?:string;
    pdfStyle: string;
    value: any;
}

export interface IRowProps {
    key?:string;
    cells:any[];
}

export interface IValueProps {
    result:Result;
}

export interface ICellBuilder
{
    createCell(props:ICellProps):any;
    createRow(props:IRowProps):any;
    createResult(props:IValueProps):any;
}

export class  HtmlCellBuilder implements ICellBuilder {
    createCell(props: ICellProps):any {
        return (<TableCell key ={props.key} 
                           className={props.className}
                           component={props.component} 
                           scope={props.scope}>
                               {props.value}
                </TableCell>);
    }

    createRow(props: IRowProps):any {
        return (<TableRow key ={props.key}>
                    {props.cells}
                </TableRow>);
    }
    createResult(props:IValueProps):any {
        const {result} = props;
        return (<div>
                    {result &&
                        <>
                            <div className="result-top">{result.points}:{result.otherPoints}</div>
                            <div className="result-bottom">{result.wins}</div>
                        </>
                    }       
                </div>);
    }
}

export class  PdfCellBuilder implements ICellBuilder {

    createCell(props: ICellProps):any {
        if(["string", "number"].indexOf(typeof props.value) >=0 ) {
            return {text:props.value, style:props.pdfStyle};
        } else {
            props.value.style = props.pdfStyle;
            return props.value;
        }
    }

    createRow(props: IRowProps):any {
        let row:any[] = [];
        props.cells.forEach(c=>{
            row.push(c);
        });
        return row;
    }

    createResult(props:IValueProps):any {
        const {result} = props;

        if(!result) return "";

        return [
            {text: `${result.points} : ${result.otherPoints}`, fontSize:10, alignment:"center"},
            {text: result.wins, fontSize:11, alignment:"center", bold:true}
        ];
    }

}



export interface ICellRenderer<CELL_TYPE> {
    createEmptyCell():CELL_TYPE;
    createPlayerCell(team:string):CELL_TYPE;
    createResultCell(value:string):CELL_TYPE;
    createPlaceCell(value:string):CELL_TYPE;
    setRightBorder(cell:CELL_TYPE, border:boolean):void;
    renderCells(cell:CELL_TYPE[][], pairings:Pairing[][], rounds:number, roundId:number):any;
}


export abstract class BaseCellRenderer<CELL_TYPE> implements ICellRenderer<CELL_TYPE> {
    
    roundsFromPlayers(players: number): number {
        return players>0?Math.ceil(Math.log(players)/Math.log(2)):0;
    }

    render(players:{[id:string]: Player}, pairings:Pairing[][], rounds:number, roundId:number): any {
        let teams:number = Math.pow(rounds, 2);
        
        console.log(rounds);
        var rows:CELL_TYPE[][] = Array.from({length:teams*(rounds+1)}, ()=>Array.from({
            length:(rounds+1) *2 }, ()=> {
                return this.createEmptyCell();
            }));

        //this.renderPart(0, 0, rows, players, pairings, rounds, roundId);
        let renderPairings = Object.values(pairings).slice(0, roundId);
        let pairs:{[id:number]:Pairing} = {};
        
        for(let i:number = 1; i  <= Object.values(renderPairings[0]).length; i ++ ) {
            pairs[i] = {key:"", show:true};
        }

        renderPairings.push(pairs);
        this.draw(0, 0, rows, Object.keys(players).length, renderPairings, players);
        return this.renderCells(rows);
    }

    pairPlayer(pair:Pairing, winner:boolean): string|null{
        if(!pair) {
            return null;
        }

        let player:string = null;

        let result1 = pair.result1 || "";
        let result2 = pair.result2 || "";

        if(pair.player1 && !pair.player2 && winner) {
            player = pair.player1;
        } else if(pair.player2 && !pair.player1 && winner) {
            player = pair.player2;
        } else if(result1 && result2 && result1 != result2) {
            if(winner) {
                player = (Number(result1) > Number(result2) ? pair.player1 : pair.player2) || "";
            } else {
                player = (Number(result1) < Number(result2) ? pair.player1 : pair.player2) || "";
            }
        }

        return player;
    }

    drawPairs(pairs: number, step: number, startRow:number, round:number, rows:CELL_TYPE[][], 
            groupStart: number, groupPlayers:number, 
            pairings:Pairing[][], players:{[id:string]: Player}, winnersGroup:boolean,
            prevGroupStart: number): number {

                let rounds = this.roundsFromPlayers(groupPlayers);
                //groupPlayers = Math.floor(Math.pow(2, rounds));
                pairs = Math.floor(Math.pow(2, rounds)) / 2;

                if(groupStart > Object.keys(players).length) {
                   // return 0;
                }

        let lastRow: number = 0;
        let next = true;
        for(let p:number = 0; p < pairs; p ++) {
            let row1: number = startRow + (p * 2) * Math.pow(2, step + 1) + Math.pow(2, step) - 1;
            let row2: number = startRow + (p * 2 + 1) * Math.pow(2, step + 1) + Math.pow(2, step) - 1;
            lastRow  = row1;// + (row2 - row1)/2;

            //console.log(`${row1}  ${round *2} ${pairs}`);

            let player1: string|null = null;
            let player2: string|null = null;
            let result1: string = "";
            let result2: string = "";

            let roundPairs:Pairing[] = pairings[round];
            
            if(groupStart == 5) {
                let a = 1;
            }

            if(roundPairs && groupPlayers > 0) {
                let pair = roundPairs[groupStart + p+1]
                if(pair) {
                    player1 = pair.player1 && players[pair.player1 || ""].name;
                    player2 = pair.player2 && players[pair.player2 || ""].name;
                    result1 = pair.result1 || "";
                    result2 = pair.result2 || "";
                    if(round == pairings.length - 2) {
                        let winner = this.pairPlayer(pair, true);
                        let looser = this.pairPlayer(pair, false); 
                        
                        let nextPair = Math.floor(p/2);
                        if(p%2) {
                            if(winner) { 
                                pairings[round+1][groupStart + nextPair + 1].player2 = winner;
                            }
                            if(looser) {
                                pairings[round+1][groupStart + nextPair + Math.ceil(groupPlayers /4) + 1].player2 = looser;
                            }
                        } else {
                            if(winner) {
                                pairings[round+1][groupStart + nextPair + 1].player1 = winner;
                            }
                            if(looser) {
                                pairings[round+1][groupStart + nextPair + Math.ceil(groupPlayers /4) + 1].player1 = looser;
                            }
                        }
                        
                    }
                } 
            } /*else if (prevPairs){
                if(groupStart == 5) {
                    let a = 1;
            }
                let pair1 = prevPairs[prevGroupStart + p*2+1];
                let pair2 = prevPairs[prevGroupStart + p*2+2];
                let key1 = this.pairPlayer(pair1, winnersGroup);
                let key2 = this.pairPlayer(pair2, winnersGroup);               
                player1 = key1 && players[key1].name;
                player2 = key2 && players[key2].name;
            }*/

            rows[row1][round *2] = this.createPlayerCell(player1||"");


            if(groupPlayers < 2) {
                next = false
                this.place ++;
                rows[row1][round *2+1] = this.createPlaceCell(`${this.place}`);           
            } else {
                rows[row1][round *2+1] = this.createResultCell(result1);
                lastRow  = row2 ;
                rows[row2][round *2] = this.createPlayerCell(player2||"");
                rows[row2][round *2+1] = this.createResultCell(result2);

                for(let i:number = row1 + 1; i <= row2; i++) {
                    this.setRightBorder(rows[i][round *2+1], true);
                }
            }
        }
        if(next) {
            let rounds = this.roundsFromPlayers(groupPlayers);
            let g1 = Math.floor(Math.pow(2, rounds-1));
            let g2 = groupPlayers - g1;
            let r1:number = this.drawPairs(Math.ceil(pairs/2), step + 1, startRow, round+1, rows, groupStart, g1, pairings, players, true, groupStart);
            let r2:number = this.drawPairs(Math.ceil(pairs/2), 0, r1+Math.pow(2, step)+1, round+1, rows, groupStart +  Math.ceil(pairs/2), g2, pairings, players, false, groupStart);

            lastRow = Math.max(lastRow, r1, r2);
        }


        return lastRow;
    }
    draw(startRow:number, startCol:number, rows:CELL_TYPE[][], roundPlayers:number, 
                pairings:Pairing[][], players:{[id:string]: Player}):number {
        let pnum = Math.ceil(roundPlayers/2)*2; 
        this.place = 0;
        return this.drawPairs(Math.floor(roundPlayers / 2), 0, startRow, startCol, rows, 0, roundPlayers, pairings, players, true, 0);
    }
    renderPart(startRow:number, startCol:number, rows:CELL_TYPE[][], players:{[id:string]: Player}, pairings:Pairing[][], rounds:number, roundId:number) {
        let teams:number = Object.keys(players).length;
        let pairs:number = teams/2;
        for(var round:number = 0; round <= rounds; round ++) {
            for(let p:number = 0; p < pairs; p ++) {
                let row1: number = startRow + (p * 2) * Math.pow(2, round + 1) + Math.pow(2, round) - 1;
                let row2: number = startRow + (p * 2 + 1) * Math.pow(2, round + 1) + Math.pow(2, round) - 1;
                rows[row1][round*2] = this.createPlayerCell("x");
                rows[row1][round*2+1] = this.createResultCell("");
                if(round != rounds) {
                    rows[row2][round*2] = this.createPlayerCell("x");
                    rows[row2][round*2+1] = this.createResultCell("");
                    for(let i:number = row1 + 1; i <= row2; i++) {
                        this.setRightBorder(rows[i][round*2+1], true);
                    }
                }
            }
            this.renderPart(startRow, startCol, rows, players, pairings.slice(pairings.length/2), rounds, roundId);
            pairs /= 2;
        }
    }

    abstract createEmptyCell():CELL_TYPE;
    abstract createPlayerCell(team:string):CELL_TYPE;
    abstract createResultCell(value:string):CELL_TYPE;
    abstract createPlaceCell(value:string):CELL_TYPE;
    abstract setRightBorder(cell:CELL_TYPE, border:boolean):void;
    abstract renderCells(cell:CELL_TYPE[][]):any;
}
