export interface IColumn {
    name:string;
    hidden:boolean;
}

export abstract class Column implements IColumn {

    constructor(init?:Partial<IColumn>) {
        Object.assign(this, init);
    }

    abstract name:string;
    hidden:boolean = false;
}

export const  Columns:{ [id: string]: Partial<IColumn>} = {
    country: {name:"Country"},
    city: {name:"City"},
    club: {name:"Club"},
    rating: {name:"Rating"},
    seed: {name:"Seed (order)"},
};