import React, { PureComponent, Component, ReactNode } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Paper, Box, Typography, Divider } from "@material-ui/core";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import firebase from "firebase/app";
import {history} from "../redux/HistoryRouter";

interface IState {
}

interface IProps extends IComponentProps {
}

class Login extends Component<IProps, IState> { 
  constructor(props:IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  async login(provider:firebase.auth.AuthProvider) {
    try {
      await firebase.auth().signInWithPopup(provider);
      let next = (new URLSearchParams(window.location.search)).get("next");
      history.push(next || "/");
    } catch( error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        alert('Email already associated with another account.');
      } else {
        console.error(error);
      }
    }
  }

  render(): ReactNode {

    return (
      <Grid
          container
          spacing={8}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '80vh' }}
        >

          <Grid item >
            <Paper>
              <Box p={2}>
                <Box my={2}>
                  <Typography variant="h4">Login</Typography>
                </Box>
                  <Divider/>
                  <Box my={4}>
                    <GoogleLoginButton onClick={() => this.login(new firebase.auth.GoogleAuthProvider())} />
                  </Box>
                  <Box my={4}>
                    <FacebookLoginButton onClick={() => this.login(new firebase.auth.FacebookAuthProvider())} />
                  </Box>
              </Box>
            </Paper>
          </Grid>   

        </Grid> 
    );
  }
}


export default Login;
