import { ICompetition } from "../models/Competition";
import IHtmlRenderer from "./IHtmlRenderer";
import React, { ReactNode, CSSProperties} from "react";
import { Pairing, Pairings } from "../models/Pairing";
import { WinsRanking, IRanking, BuchholzRanking, PointsDiffRanking, PointsRanking, Rankings, Round} from "../algorithms/rankings/Ranking";
import PairingRow from "../components/competitions/PairingRow";
import { List, Divider, TableCell, Table, TableRow, TableHead, TableBody, Typography, Hidden, Paper, Box} from "@material-ui/core";
import ICompetitionManager, { Player } from "../algorithms/managers/ICompetitionManager";
import IPdfRenderer from "./IPdfRenderer";
import { Columns } from "../common/Columns";
import players from "../components/players";
import { ArrowDropUp, ArrowDropDown, ExpandLess, ExpandMore } from "@material-ui/icons";

export interface IRenderer extends IHtmlRenderer, IPdfRenderer {
  landscapeTable(): boolean;
}


function toRomenian(num: number):string {
  let b = 0;
  let s = '';

  let n:number = num;

  for( let a=5; n > 0; b++, a ^= 7) {
    let o = n % a;
    n = n / a ^ 0;
    for(; o--;) {        
      s ='IVXLCDM'[o > 2? b + n - (n &= -2) + (o = 1): b]+s;
    }
  }
  return s
}

export default class BaseRenderer implements IRenderer {

  landscapeTable():boolean { return false;}

  createStandingsPdf(): any[] {
    let {competition, rankings, players, ranks, ordered} = this;

    let columns:string[] = competition&&competition.columns||[];

    let header:object[] = [{text:"Rank", style:"tableHeader"},
                  {text:"Name", style:"tableHeader"}];
    let widths:string[] = ["auto", "*"];

    columns
      .filter((column)=>!Columns[column].hidden)
      .map((column) => {
        header.push({text:Columns[column].name, style:"tableHeader"});
        widths.push("auto");
      });

    rankings
      .filter((ranking)=>ranking.visible)
      .map((ranking, index) => {
        header.push({text:ranking.shortName, style:"tableHeader"});
        widths.push("auto");
      });

    let playerCount = players && Object.keys(players).length || 0;
    let tableBody:object[] = [header];

    if(ordered) {
      ordered.forEach((playerId, i) => {
  
        let rowStyle = this.rowStyle(i, this.roundId, playerCount);
        let cols:any[] = [
          {style:rowStyle, text: this.placeNo(i, this.roundId, playerCount)}, 
          {style:rowStyle, text: players[playerId].name}
        ];
        columns
          .filter((column)=>!Columns[column].hidden)
          .map((column) => {
            cols.push({style:rowStyle, text:players[playerId][column]});
          });

        if(ranks) {
          ranks.map((r, i:number)=> {
            if(rankings[i].visible) {
              cols.push({style:rowStyle, text:r.get(playerId), alignment:"center"});
            }
          });
        }

        tableBody.push(cols);
      });
    }

    let content:object[] = [
      {
        style: "mainTable",
        table: {
          widths: widths,
          body: tableBody
        }
      }
    ];

    rankings
      .filter((r)=>r.visible)
      .map((r) => {
        content.push({text:`${r.shortName} - ${r.name}`, style:"footnote"});
      });

    return content;
  }

  calculateRanks(roundId: number):Map<string, number>[] {

    let ranks:Map<string, number>[] = [];
    let rounds:Round[] = [];

    if(this.allPairings) {
      for( let i:number = 1; i <= roundId; i++) {
        let roundPairings:Pairing[] = this.allPairings[i];

        let pairings:any = [];

        var p:Pairing;
        if(roundPairings) {
          for( let j:number = 1; p = roundPairings[j]; j++) {
            pairings.push(
              {
                home: p.player1,
                home_points:p.player2?p.result1:(p.result1),
                guest: p.player2,
                guest_points: p.result2
              }
            );
          }
        }
        rounds.push( { pairings: pairings });
      }

      for(var r of this.rankings) {
        ranks.push( r.rankings( this.players, rounds));
      }
    }
    return ranks;
  }
  createPlayersPdf(): any[] {
    const { competition, players} = this;

    let columns:string[] = competition && competition.columns || [];
    let header:object[] = [{text:"No", style:"tableHeader"},
                  {text:"Name", style:"tableHeader"}];
    let widths:string[] = ["auto", "*"];
    columns.map((column, index) => {
      header.push({text:Columns[column].name, style:"tableHeader"});
      widths.push("auto");
    });

    let tableBody:object[] = [header];

    Object.values(players).forEach((player, i) => {
      let cols:any[] = [i+1, player.name];
      columns.map((column, index) => {
        cols.push({text:player[column]});
      });
      tableBody.push(cols);
    });

    let content:object[] = [
      {
        style: "mainTable",
        table: {
          widths: widths,
          body: tableBody
        }
      }
    ];
    return content;
  }
  createPairingsPdf(): any[] {
    const {wins, players, competition} = this;
    const pairings:Pairing[]|null = this.currentPairings;

    let tableBody:any[] = [
      [{text:"Line", style:"tableHeader"},
      {text:"Name", style:"tableHeader"},
      {text:"Wins", style:"tableHeader"},
      {text:"Result", style:"tableHeader"},
      {text:"Wins", style:"tableHeader"},
      {text:"Name", style:"tableHeader"}]
    ];

    if(pairings) {
      Object.values(pairings).forEach((p:Pairing, i) => {
          if(p.player1 || p.player2) {
          var name1: string = "";
          var name2: string = "";
          var wins1: number|string = "";
          var wins2: number|string = "";
          var result1 : string|undefined="";
          var result2 : string|undefined="";

          if(p.player1) {
            name1 = players[p.player1].name;
            wins1 = wins.get(p.player1) || 0;
            result1 = p.result1||"";
          }

          if(p.player1 && p.player2) {
            name2 = players[p.player2].name;
            wins2 = wins.get(p.player2) || 0;
            result2 = p.result2||"";
          } else if (p.player2) {
            name1 = players[p.player2].name;
            wins1 = wins.get(p.player2) || 0;
            result1 = p.result2||"";
          }

          const spot = this.getSpot(p);

          tableBody.push([spot||"bye", name1, {text:wins1, alignment:"center"}, {text:`${result1}:${result2}`,
                          alignment:"center"}, {text:wins2, alignment:"center"}, name2]);        
        }  
      });
    }

    let content:any[] = [
      {
        style: "mainTable",
        table: {
          widths: ["auto", "*", "auto", "auto", "auto", "*"],
          body: tableBody
        }
      }
    ];

    return content;

  }

  createTableHtml(): React.ReactNode {
    throw new Error("Method not implemented.");
  }
  createTablePdf(): any[] {
    throw new Error("Method not implemented.");
  }

  competition: ICompetition;
  players:{[id:string]: Player};
  roundId:number;
  rounds:number;
  allPairings:Pairing[][];
  currentPairings:Pairing[]|null = null;
  rankings: IRanking[];
  ordered:string[];

  wins: Map<string, number>;
  winsBeforeRound: Map<string, number>;
  ranks: Map<string, number>[];
  places: Map<string, string> = new Map<string, string>();
  prevRanks: Map<string, number>[]|null = null;


  constructor(competition: ICompetition, players:{[id:string]: Player}, allPairings:Pairing[][], roundId:number, rounds:number) {
    this.competition = competition;
    this.players = players;
    this.roundId = roundId;
    this.rounds = rounds;
    this.allPairings = allPairings;

    if(this.allPairings) {
      let currentPairings =Object.assign([], this.allPairings[this.roundId]);
      let self = this;
      this.currentPairings = currentPairings.sort((n1:Pairing, n2:Pairing) => {
        let spot1 = self.getSpot(n1);
        let spot2 = self.getSpot(n2);
        if(spot1 == spot2) return 0;
        if(!spot1) return 1;
        if(!spot2) return -1;
        return spot1 - spot2;
      });
    }

    this.wins = this.calculateWins(this.roundId, this.allPairings);
    this.winsBeforeRound = this.calculateWins(this.roundId - 1, this.allPairings);

    this.rankings = [
      new WinsRanking(), new BuchholzRanking(), new PointsDiffRanking(), new PointsRanking()
    ];

    if(competition.rankings) {
      this.rankings = [];
      competition.rankings.forEach((r)=> {
        let f:any = Rankings[r];
        if(f) {
          this.rankings.push(new f());
        } else {
          console.log(`"${r}" ranking not found`);
        }
      });
    }
    roundId = Number(this.roundId);    
    this.ranks = this.calculateRanks(roundId);
    if(roundId > 1) {
      this.prevRanks = this.calculateRanks(roundId-1);
    }

    this.ordered = this.players && this.orderedPlayers(this.ranks);
    
    let placeFrom = 1;
    let placeTo = 1;

    let playerIds = this.ordered;
    for(let i = 0; i < playerIds.length; i++) {
      let id = playerIds[i];
      
      let equal = i < playerIds.length-1;

      if(equal) {
        let nextId =  playerIds[i+1];
        this.ranks.forEach((rank, i) => {
          if(rank.get(id) !== rank.get(nextId)) {
            equal = false;
          }
        });
      }

      if(equal) {
        placeTo++;
      } else {
        
        if(placeFrom === placeTo) {
          this.places.set(id, toRomenian(placeTo));
        } else {
          let place = `${toRomenian(placeFrom)} - ${toRomenian(placeTo)}`;
          for(let p = placeFrom; p <= placeTo; p++) {
            this.places.set(playerIds[p-1], place);
          }
        }
        placeTo ++;
        placeFrom = placeTo;
      }
    }
  }

  
  getSpot(pairing: Pairing):number|null {
    return pairing.player1 && pairing.player2 ? pairing.spot? pairing.spot : Number(pairing.key): null;
  }

  createPairingsHtml(readOnly:boolean): ReactNode {
    let self:BaseRenderer = this;
    let pairings:Pairing[]|null = this.currentPairings;

    if(!pairings) {
      return null;
    }
    let pairing:ReactNode = Object.values(pairings).map(function(pairing:Pairing, i:number):ReactNode {
        if(pairing.key) {
          let spot = pairing.player1 && pairing.player2 ? self.getSpot(pairing): pairing.key;
          return (
            <div key={`${pairing.key}_${self.roundId}`}>
              <Divider/>
              <PairingRow pairing={pairing} id={`${pairing.key}_${self.roundId}`} spot={`${spot}`}         //{`${i+ self.competition.firstLine}`}
                          players={self.players} wins={self.winsBeforeRound}
                          competitionId={self.competition.id||""} roundId={`${self.roundId}`} readOnly={readOnly}/>
            </div>);
        }
        return null;
      });
    return (
      <List >
        {pairing}
        <Divider key={"divider-X"}/>
      </List>
    );
  }

  placeNo(index:number, round:Number, playerCount:number):string {
    return `${index + 1}`;
  }

  rowStyle(index:number, round:Number, playerCount:number):string {
    return index % 2 ? "light" :"dark";
  }

  createStandingsStream(): ReactNode {
    console.log("BaseRenderer.createStandingsHtml");
    let {competition, ordered, players, ranks} = this;

    if(!(competition && ordered && players && ranks)) {
      return null;
    }

    let columns:string[] = competition&&competition.columns||[];

    const style:CSSProperties = {};
    const headerStyle:CSSProperties = {
      paddingLeft: 0,
      paddingRight: 0
    };
    const style2:CSSProperties = { fontWeight:600};
    let playerCount = players && Object.keys(players).length || 0;

    var player:ReactNode = ordered.map((playerId:string, index:number) => {
      let player:Player = players[playerId];
      let cells:ReactNode = columns.map((c, index)=> {
        return (
          <td key={`col-${index}`}><div className="cell-back"></div>{player[c]} </td>
                );
        });

        return (
          <tr key={`player-${index}`}>
            <td className="number" key="number"><div className="cell-back"></div> {this.placeNo(index, this.roundId, playerCount)} </td>
            <td key="name"><div className="cell-back"></div>{player.name}</td>
            {cells}
            {
            ranks.map((r, index) => {
                return this.rankings[index].visible?
                    ( 
                      <td key={`player-${index}`}><div className="cell-back"></div>{r.get(playerId)}</td>
                    ) :null;
              })
            }
          </tr>)
      });

    let headerCells:ReactNode = columns.map((c, index)=> {
      return (
        <th key={`col-${index}`}><div className="cell-back"></div>{Columns[c].name}</th>
      );
    });
    return (
      <>
        <table className="stream"  cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th key="no"><div className="cell-back"></div>No.</th>
              <th key="name"><div className="cell-back"></div>Name</th>
              {headerCells}
                  {
                  this.rankings
                  .filter( (ranking)=>ranking.visible)
                  .map((ranking, index) => {
                      return (
                        <th key={`renk-${ranking.shortName}`}><div className="cell-back"></div>{ranking.shortName}</th>
                      )
                    })
                  }
              </tr>
          </thead>
          <tbody>
            {player}
          </tbody>
        </table>
      </>
    );
  }

  createStandingsHtml(): ReactNode {
    let {competition, ordered, players, ranks} = this;

    if(!(competition && ordered && players && ranks)) {
      return null;
    }

    let columns:string[] = competition&&competition.columns||[];

    const style:CSSProperties = {};
    const headerStyle:CSSProperties = {
      paddingLeft: 0,
      paddingRight: 0
    };
    const style2:CSSProperties = { fontWeight:600};
    let playerCount = players && Object.keys(players).length || 0;
    
    let prevOrdered:string[]|null = null;
    if(this.prevRanks && this.players) {
      prevOrdered = this.orderedPlayers(this.prevRanks);
    }

    var player:ReactNode =  ordered.map((playerId:string, index:number) => {
      let player:Player = players[playerId];
      let cells:ReactNode = columns.map((c, index)=> {
        return (<TableCell align="center" key={c}>
                  <span style={style}>{player[c]}</span>
                </TableCell>);
        });
        
        let rankDiff = 0;

        if(prevOrdered) {
          rankDiff = prevOrdered.findIndex((p)=>p==playerId) - index;
        }

        return (
        <TableRow  key={playerId} className={ this.rowStyle(index, this.roundId, playerCount)}>
          <TableCell align="center"><span style={style}>
            <div style={{fontWeight:600}}>{this.placeNo(index, this.roundId, playerCount)}</div>
            </span></TableCell>
            <Hidden smDown>
              <TableCell align="center">
                {rankDiff != 0 &&
                 <span className={rankDiff < 0? "negative": "positive"}>
                  {rankDiff > 0 &&
                   (<><ExpandLess fontSize="small" className="rankChange"/>{Math.abs(rankDiff)}</>)
                  || 
                    (<><ExpandMore fontSize="small" className="rankChange"/>{Math.abs(rankDiff)}</>)
                  }
                </span>
              }
              </TableCell>
            </Hidden>            
          <TableCell  component="th" scope="row">{player.name}</TableCell>
          <Hidden xsDown>{cells}</Hidden>
          {
            ranks.map((r, index) => {
                return this.rankings[index].visible?
                    ( <TableCell align="center" key={`${playerId}-${index}`}>
                          <span style={index?style:style2}>{r.get(playerId)}</span>
                        </TableCell>):null;
              })
            }
        </TableRow >);
      });

    let headerCells:ReactNode = columns.map((c, index)=> {
      return (<TableCell align="center" key={c}>
                <span style={style}>{Columns[c].name}</span>
              </TableCell>);
    });
    return (
      <>
        <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" ><span style={style}>No.</span></TableCell>
              <Hidden smDown><TableCell></TableCell></Hidden>
              <TableCell ><div style={style}>Name</div></TableCell>
              <Hidden xsDown>{headerCells}</Hidden>
              {
              this.rankings
              .filter( (ranking)=>ranking.visible)
              .map((ranking, index) => {
                  return (
                    <TableCell align="center" key={ranking.shortName} style={headerStyle}>
                      <span>{ranking.shortName}</span>
                    </TableCell>);
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {player}
          </TableBody>
        </Table>
        <Box p={1}>
          {
            this.rankings
              .filter((ranking)=>ranking.visible)
              .map((ranking, index) => {
                return ( <Typography variant="caption" key={index}>{ranking.shortName} - {ranking.name}<br/></Typography>);
              })
          }
        </Box>
      </>
    );
  }

  calculateWins(roundId:number, allPairings:Pairing[][]): Map<string, number> {

      let rounds:any[] = [];

      if(allPairings) {

        for( let i:number = 1; i <= Number(roundId); i++) {
          let round:Pairing[] = allPairings[i];

          let pairs:any = [];

          var p:Pairing;
          for( let j:number = 1; p = round[j]; j++) {
            pairs.push(
              {
                home: p.player1,
                home_points:p.player1?p.player2?p.result1:(p.result1):null,

                guest: p.player2,
                guest_points: p.player2?p.player1?p.result2:(p.result2):null,
              }
            );
          }
          rounds.push( { pairings: pairs });
        }
      }
      return new  WinsRanking().rankings(this.players, rounds);
  }

  orderedPlayers(ranks: Map<string, number>[] ):string[] {
    let {players} = this;

    return Object.keys(players).sort((a:string, b:string) => {

      for(let i:number = 0; i < ranks.length; i++) {
        var diff:number = (ranks[i].get(b) || 0) - (ranks[i].get(a) || 0);
        if(diff !== 0) {
          return diff;
        }
      }
      return 0;
    });

  }
}