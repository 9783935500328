const loadScript = (name:string, src:string, callback:(()=>void)|undefined=undefined) => {
    var scriptId = 'script-' + name
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = src;
        script.id = scriptId;
        script.async = true;
        script.onload = () => { 
            if (callback) callback();
        };
        document.body.appendChild(script);
    } else {
        console.log("do we need this?");
        if (callback) callback();
    }
};

declare global {
    interface Window {
        gapi: any;
        _urq: any;
        _paq: any;
    }
}

interface IIndexable {
    [key: string]: any;
}

export default [
    function initGoogle() {
        loadScript("googleMaps", "https://maps.googleapis.com/maps/api/js?key=AIzaSyA5JKicMUQb1_lPv50dUR2nd8l8Yb9sqZw&libraries=geometry,drawing,places");
    },
    function initUserReport() {
        window._urq = window._urq || [];
        window._urq.push(['initSite', '58279549-c746-4a9b-847b-0990cc16abcc']);
        loadScript("userReport", ('https:' == document.location.protocol ? 'https://cdn.userreport.com/userreport.js' : 'http://cdn.userreport.com/userreport.js'));
    },
    function initMotomo() {
        var url = "//piwik.two.lt/";
        var _paq = window._paq || [];
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        _paq.push(['setTrackerUrl', url + 'matomo.php']);
        _paq.push(['setSiteId', '8']);
        var u="//piwik.two.lt/";
        loadScript("motomo", url);

        var _wr = function(type:string) {
          var orig = (history as IIndexable)[type];
          return function() {
              // @ts-ignore
              let rv = orig.apply(this, arguments);
              let e:any = new Event(type);
              e.arguments = arguments;
              window.dispatchEvent(e);
              return rv;
          };
        };
        history.pushState = _wr('pushState');
        history.replaceState = _wr('replaceState');
      
        var currentUrl = location.href;
        window.addEventListener('pushState', function(e) {
          _paq.push(['setReferrerUrl', currentUrl]);
            currentUrl = window.location.href;
            _paq.push(['setCustomUrl', currentUrl]);
            _paq.push(['setDocumentTitle', 'All Results']);

            // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
            _paq.push(['deleteCustomVariables', 'page']); 
            _paq.push(['setGenerationTimeMs', 0]);
            _paq.push(['trackPageView']);

            // make Matomo aware of newly added content
            var content = document.getElementById('content');
            _paq.push(['MediaAnalytics::scanForMedia', content]);
            _paq.push(['FormAnalytics::scanForForms', content]);
            _paq.push(['trackContentImpressionsWithinNode', content]);
            _paq.push(['enableLinkTracking']);
        });
    }
];