import {Player} from "./ICompetitionManager";
import BaseCompetitionManager from "./BaseCompetitionManager";
import {Pairing} from "../../models/Pairing";
import { ICompetition } from "../../models/Competition";
import RoundRobinRenderer from "../../render/RoundRobinRenderer";
import { IRenderer } from "../../render/BaseRenderer";
import { IRaport, Raports } from "../../common/Raports";


class Pair {
    round?: number;
    home?: Player;
    away?: Player;
}

export default class RoundRobin extends BaseCompetitionManager {

  allRounds: boolean = false;

  defaultRankings: string[] =
              ["WinsRanking", "PointsDiffRanking"];

  competition:ICompetition;
  rounds:Pairing[][];
  players:{[id:string]: Player};
  round: number;

  constructor(competition:ICompetition, rounds:Pairing[][], players:{[id:string]: Player}, round: number) {
    super();
    this.competition = competition;
    this.rounds = rounds;
    this.players = players;
    this.round = round;
  }
     
  availableRaports(): IRaport[] {
    let raports = super.availableRaports();
    raports.push(Raports.table);
    return raports;
  }

  getRoundCount(): number {
    return this.players?this.roundsFromPlayers(Object.keys(this.players).length):0;
}

  createRenderer(competition: ICompetition, round: number): IRenderer {
    return new RoundRobinRenderer(competition, this.players, this.rounds, round, -1);
  }

  roundsFromPlayers(players: number): number {
        return  players > 1? players + players % 2 - 1 : 0;
    }

  _createPairings(competition:ICompetition,rounds:Pairing[][], players: {[id:string]: Player}, round: number): Pairing[] {

      let playerList:(string|null)[] = Object.keys(players);
      //console.log("Round robin", playerList)

      if (playerList.length % 2 == 1) {
        playerList.push(null);
      }
    
      let roundPairings = this.roundPairing(playerList, round);
      //console.log("Round robin", roundPairings)
      return roundPairings;
    }

    standings(competition:ICompetition, rounds:Pairing[][], players: string[], afterRound:number):Player[] {

      return [];
    }
    
    // round zero based
    roundPairing(players:string[], round:number): Pairing[] {
      const playerCount = players.length;
      const half = playerCount / 2;
      
      let playerIndexes:number[] = players.map((_, i) => i);
    
      let rotate = (half * round) % (playerCount - 1);
      
      const firstHalf = playerIndexes.slice(0, rotate);
      const secondHalf = playerIndexes.slice(rotate, playerCount-1);
      
      let indexes = playerIndexes;
      playerIndexes = secondHalf.concat(firstHalf);
      playerIndexes.push(indexes[indexes.length -1]);
    
    
      let roundPairings:Pairing[] = [];
    
      for (let i = 0; i < half; i++) {
        let player1 = players[playerIndexes[i]];
        let player2 = players[playerIndexes[playerIndexes.length - i - 1]];
        if( player1&&player2 )
        if(round % 2 && i == 0) {
          roundPairings.push(new Pairing({
            player1: player2,
            player2: player1,
          }));
        } else {
          roundPairings.push(new Pairing({
            player1: player1,
            player2: player2,
          }));
        }
      }
    
      return roundPairings;
    }
}