import {updateFeatureAction} from "../actions";
import store from "../redux/store";

export interface IFeature {
  name: string;
  isActive: boolean;
}
export function enableFeature(feature:string) {
  setFeature(feature, true);
}

export function setFeature(feature:string, active:boolean) {
  console.log("setfeature", feature, active);
  let features:IFeature[] = store.getState().features;
  features
    .filter(f=>f.name === feature && f.isActive !== active)
    .forEach((f)=>store.dispatch(updateFeatureAction(f.name, active)));
}

function updateFeatureState(state:IFeature[], feature:string, active:boolean) {
    const newFeatures = [...state];
    newFeatures
      .filter(f=>f.name === feature && f.isActive !== active)
      .forEach((f)=>f.isActive = active);
    
    return newFeatures;
}

const features = (state:IFeature[] = [
      { name: "vipOnly", isActive: true },
      { name: "adminOnly", isActive: true },
      { name: "beta-users", isActive: false }
      ], action: any) => {

   //   console.log("Action", action);
    switch (action.type) {
      case 'ENABLE_FEATURE':
        return updateFeatureState(state, action.feature, true); 
      case 'DISABLE_FEATURE':
        return updateFeatureState(state, action.feature, false); 
      case 'SET_FEATURE':
        return updateFeatureState(state, action.feature, action.value); 
      default:
        return state;
    }
  }
  
  export default features;