import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { connectRouter } from "connected-react-router";
import rounds from "../reducers/rounds";
import features from "../reducers/features";

export default (history:any)=>combineReducers({
  router: connectRouter(history),
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  features,
});