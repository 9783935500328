import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import {history} from "./HistoryRouter";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";

import firebaseConfig from "../firebaseConfig";
import rootReducer from "./reducers";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import { reduxFirestore, getFirestore } from "redux-firestore";

// const loggerMiddleware = createLogger();

firebase.initializeApp(firebaseConfig);
// const perf:Performance =
firebase.performance();

firebase.firestore().settings({});
firebase.firestore().enablePersistence({synchronizeTabs:true})
  .catch(function(err:any):void {
    console.log(`Error: ${err}`);
      if (err.code === "failed-precondition") {
          // multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === "unimplemented") {
          // the current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
const initialState:any = {
};

let store = createStore(
    rootReducer(history) ,
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunkMiddleware.withExtraArgument({getFirestore}), // lets us dispatch() functions
 //       loggerMiddleware // neat middleware that logs actions
      ),
      reduxFirestore(firebase)
    ),
  );

  export default store;
