import React, { Component, ReactNode } from "react";
import {TextField, FormGroup, Grid, Paper, Typography, Select, MenuItem, FormControl, FormLabel,
  FormHelperText, InputLabel, Divider, Button, Box} from "@material-ui/core";
import {connect} from "react-redux";
import { compose, withHandlers  } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { history,  } from "../../redux/HistoryRouter";
import firebase from "firebase/app";
import { firestore } from "firebase";
import { ICompetition } from "../../models/Competition";
import { Organiser } from "../../models/Organiser";
import { Managers } from "../../algorithms/managers/Managers";
import { Pairing, Round } from "../../models/Pairing";
import { Rankings, IRanking, Ranking } from "../../algorithms/rankings/Ranking";


interface IState {
  fromCompetition?: string;
  firstPlayer?: string;
  lastPlayer?: string;
  errors?: any;
  canContinue?: boolean;
}
interface IProps extends IComponentProps {
  competition?: ICompetition;
  competitions?: ICompetition[];
  auth : any;
}

class ImportPlayers extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      errors: {},
      canContinue: false
    };
  }

  canEdit(): boolean {
    var user:firebase.User|null  = firebase.auth().currentUser;
    // @ts-ignore:
    return this.props.competition && (this.props.competition.owner === (user && user.uid));
  }

  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> =
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const state:IState = this.state;

    // @ts-ignore:
    state[e.target.name] = e.target.value;
    state.errors[e.target.name] = !Boolean(e.target.value);

    state.canContinue = !Object.values(state.errors).some(e=>e);

    this.setState(state);
  }

  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> =
  (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const state:IState = this.state;

    if(e.target.required) {
      // @ts-ignore
      state.errors[e.target.name] = !Boolean(e.target.value);
    }

    this.setState(state);
  }

  onSubmitButton: React.MouseEventHandler<HTMLButtonElement> =
  (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log("onSubmit");
    if(!e!.currentTarget!.form!.reportValidity()) {
      return;
    }
    this.importPlayers();
  }

  componentDidUpdate(prevProps:IProps):void {
    if(this.props.competition && prevProps.competition !== this.props.competition) {
      // todo
    }
  }

  async importPlayers(): Promise<void> {
    console.log("importPlayers");
    const {fromCompetition} = this.state;
    const now:firestore.Timestamp = firestore.Timestamp.now();
    const ref:firestore.CollectionReference  = this.props.firestore.collection(`competitions`);
    const {params} = this.props.match;


    let docRef = await ref.doc(fromCompetition).get();
    let competition:ICompetition = docRef.data();

    const refRounds:firestore.CollectionReference  = this.props.firestore.collection(`competitions/${fromCompetition}/rounds`);
    let querySnapshot = await refRounds.get();
    let pairings = [];
    querySnapshot.forEach(async (doc) => {
      let d = doc.data();
      pairings.push(d);
    });


    const refPlayers:firestore.CollectionReference  = this.props.firestore.collection(`competitions/${fromCompetition}/players`);
    let playersSmnapshot = await refPlayers.get();
    let players:Player[]=[];
    playersSmnapshot.forEach(async (doc) => {
      let d = doc.data();
      d.id = doc.id;
      players.push(d);
    });
    

    let ranks = this.calculateRanks(competition, pairings);
    for(let r of ranks ){
      console.log(r);
    }

    let ordered = this.orderedPlayers(players, ranks);

/*
    let c:any = {...props.competition};
    c.date = new Date(c.date);
    c.modified = now;



    if(state.update) {
      await ref.doc(params.competitionId).set(c);
    } else {
      c.created=now;
      c.rankings= ["WinsRanking", "BuchholzRanking", "FineBuchholzRanking", "PointsDiffRanking"];
      c.owner = firebase.auth().currentUser!.uid;
      await ref.add(c);
    }
*/

 const {firstPlayer,lastPlayer} = this.state;
            for(let num:number = Number(firstPlayer); num <= Number(lastPlayer); num++) {
              let player = players[num-1];
              delete player.id;
              player.seed = num;
              player.owner = firebase.auth().currentUser.uid;

              console.log(player);

            const {firestore, competition} = this.props;
            const ref = firestore.collection(`competitions/${competition.id}/players/`);
            await ref.add(player);
          }
    //history.goBack();

    alert("Done");
  }

  calculateRanks(competition: ICompetition, pairings:Pairing[][] ):any {

    if(!(pairings)) {
      return null;
    }

    let ranks:IRanking[] = [];

    let rankings:IRanking[] = [];

    if(competition.rankings) {
      competition.rankings.forEach((r)=> {
        var x:any = Rankings[r];
        if(x) {
          rankings.push(new x());
        } else {
          console.log(`"${r}" ranking not found`);
        }
      });
    }

    const roundId = pairings.length;
    let rounds:Round[] = [];

    for( let i:number = 0; i < Number(roundId); i++) {
      let roundPairings:Pairing[]= pairings[`${i}`];

      let pairings2 = [];

      var p:Pairing ;
      for( let j:number = 1; p = roundPairings[`${j}`]; j++) {
        pairings2.push(
          {
            home: p.player1,
            home_points:p.player2?p.result1:(p.result1),
            guest: p.player2,
            guest_points: p.result2
          }
        );
      }
      rounds.push( { pairings: pairings2 });
    }

    for(var r of rankings) {
      ranks.push( r.rankings(null, rounds));
    }

    return ranks;
  }

  orderedPlayers(players:Player[], ranks):  Player[] {

    if(!players || !ranks) {
      return null;
    }

    return players.sort((a, b) =>{

      for(let i:number = 0; i < ranks.length; i++) {
        let diff:number = (ranks[i].get(b.id) || 0) - (ranks[i].get(a.id) || 0);
        if(diff !== 0) {
          return diff;
        }
      }
      return 0;
    });
  }

  render(): ReactNode {
    const {auth, competition, competitions} = this.props;
    const {errors, firstPlayer, lastPlayer, fromCompetition, canContinue } = this.state;

    if(!(auth && isLoaded(auth) && competition && competitions)) {
      return (<div>Loading ...</div>);
    }

    let dropdown:ReactNode[] = Object.values(competitions).map((c:ICompetition) => (
        <MenuItem key={c.id} value={c.id}>{c.title}</MenuItem>
    ));

    return (
      <Grid  container direction="row" justify="center">
        <Grid item lg={6} md={8} sm={12}>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h5">
              Import Players
            </Typography>
            <Divider />
            <form>
              <FormGroup> 
                <FormControl>
                  <InputLabel htmlFor="fromCompetition">Import from competition</InputLabel>
                    <Select id="fromCompetition"
                      required
                      value={fromCompetition||""}
                      onChange={this.onChange}
                      name ="fromCompetition">
                    {dropdown}
                  </Select> 
                </FormControl>
              </FormGroup>

              <FormGroup  row>
                <TextField required
                  error={errors.rounds===true}
                  id="firstPlayer"
                  name="firstPlayer"
                  label="Import from place"
                  value={this.state.firstPlayer||""}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  type="number"
                  style={{ margin: 8 }}
                  inputProps={{
                    min:"1"
                  }}
                  margin="normal"
                />

                <TextField required
                  error={errors.rounds===true}
                  id="lastPlayer"
                  name="lastPlayer"
                  label="Import to place"
                  value={lastPlayer||""}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  type="number"
                  style={{ margin: 8 }}
                  inputProps={{
                    min:"1"
                  }}
                  margin="normal"
                />
              </FormGroup>

              <div className="flex-cell first">
                <Button disabled={!canContinue} variant="contained" color="primary" onClick={this.onSubmitButton}>
                  Import
                </Button>
              </div>
            </form>
            </Paper>
          </Grid>
        </Grid>
    );
  }
}






export default compose<IProps, IProps>(
  connect<any, IProps, IProps>(({firebase: {auth}, firestore: {ordered} }:any, props:IProps) => {

    let competition:ICompetition|undefined;
    let organiser: Organiser|undefined|null = undefined;

    if(auth && auth.isLoaded) {
      if(ordered.organiser ) {
        organiser = ordered.organiser[0]?ordered.organiser[0]:null;
      }
      if( organiser === null || organiser && (auth.uid !== organiser.owner)) {
        organiser = null;
      }
    }

    if( props.match.params.competitionId != null) {
      competition = ICompetition.fromFirestore(ordered.competition[0]);
    }

    return {
      auth,
      competition: competition,
      competitions: ordered.competitions
    };
  }),
  firestoreConnect((props: IProps) =>  {
    return props.auth?
    [
      {
        collection: "competitions",
        orderBy: [["date", "desc"]]
      },
      { collection: `competitions`,
        doc: props.match.params.competitionId,
        storeAs: "competition"
      }
    ]:
    [];
  })

)(ImportPlayers);
