import React, {ReactNode} from "react";
import Component from "mson-react/lib/component";
import Form from "@rjsf/material-ui";


const definition = {
    component: "Form",
    fields: [
      {
        name: "heading",
        component: "Text",
        text: "# Form using [MSON](https://github.com/redgeoff/mson)"
      },
            
      {
        name: "email",
        component: "EmailField",
        label: "El.pašto adresas",
        help: "Kontaktinis el. pašto adresas, kuriuo bus išsiųsta papildoma informacija"
      },

      {
        name: "labelPlayer1",
        component: "Text",
        text: "Žaidėjas 1"
      },
      {
        name: "firstName1",
        component: "PersonNameField",
        label: "Vardas",
        required: true,
        block: false
      },
      {
        name: "lastName1",
        component: "PersonNameField",
        label: "Pavardė",
        required: true,
        block: false
      },
      {
        name: "phone1",
        component: "PersonNameField",
        label: "Telefono nr.",
        required: true,
        block: true
      },
      {
        name: "labelPlayer2",
        component: "Text",
        text: "Žaidėjas 2"
      },
      {
        name: "firstName2",
        component: "PersonNameField",
        label: "Vardas",
        required: true,
        block: false
      },
      {
        name: "lastName2",
        component: "PersonNameField",
        label: "Pavardė",
        required: true,
        block: false
      },
      {
        name: "phone2",
        component: "PhoneField",
        label: "Telefono nr.",
        required: true,
        block: true
      },
      {
        name: "labelConfirm",
        component: "Text",
        text: "Patvirtinu, kad su varžybų [nuostatais](https://github.com/redgeoff/mson) susipažinau"
      },
      {
        name: "confirm",
        label: "Patvirtinu, kad su varžybų [nuostatais](https://github.com/redgeoff/mson) susipažinau",
        component: "BooleanField",
        required: true,
        block: false
      },
      {
        name: "labelConfirm2",
        component: "Text",
        text: "Patvirtinu, kad su varžybų [nuostatais](https://github.com/redgeoff/mson) susipažinau"
      },
      {
        name: "submit",
        component: "ButtonField",
        type: "submit",
        label: "Submit",
        icon: "Save"
      },
      {
        id:"123",
        name: "reset",
        component: "ButtonField",
        label: "Reset",
        icon: "Clear"
      }
    ],
    validators: [
      {
        where: {
          "fields.confirm.value": false
        },
        error: {
          field: "confirm",
          error: "Kad tęsti, turite susipažinti su nuostatais"
        }
      }
    ]
  };

const schema = {
  title: "Todo",
  type: "object",
  required: ["title"],
  properties: {
    email: {type: "string", title: "El. pašto adresas", default: "A new task", format:"email"},
    title: {type: "string", title: "Title", default: "A new task"},
    done: {type: "boolean", title: "Done?", default: false}
  }
};
const log = (type) => console.log.bind(console, type);


class Registration extends React.Component {

    onMsonMount = (component:any):void =>{
        console.log("c", component);
        // Load any initial data, e.g. from an API
        component.component.setValues({
            id: "abc123",
            firstName: "Bob",
            lastName: "Marley",
            email: "bob@example.com"
        });
    }

    onMsonReset(props:any): void {
        props.component.reset();
    }

    onMsonSubmit(props:any): void {
        var {component} = props;
        console.log("submitting", component.getValues());

        // Simulate response from API saying that email address is already in use and report this
        // error to the user
        if (component.get("fields.email.value") === "taken@example.com") {
            component.set({ "fields.email.err": "already in use" });
        } else {
            // Everything was successful so redirect, show confirmation, etc...
        }
    }


    render(): ReactNode {
        console.log(this.props);
        
        return (<>
                    <h1>Registration</h1>
                    <Form schema={schema}
                            onChange={log("changed")}
                            onSubmit={log("submitted")}
                            onError={log("errors")} />

                    <Component
                        definition={definition}
                        onMount={this.onMsonMount}
                        // Clear the form
                        onReset={this.onMsonSubmit}
                        onSubmit={this.onMsonReset}
                    />            
            </>);
    }
}

export {Registration};