import urljoin from "url-join";

import React, { Component, ReactNode } from "react";
import { Typography, Grid, Avatar, FormControl, Hidden, Select, MenuItem} from "@material-ui/core";
import { history } from "../../redux/HistoryRouter";

import { connect } from "react-redux";
import { compose } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { Pairing } from "../../models/Pairing";
import { Organiser } from "../../models/Organiser";
import { ICompetition } from "../../models/Competition";
import RoundPairings from "./RoundPairings"
import Standings from "./Standings";

interface IState {
  logoImage?: string;
  page?:string;
  roundId?: string;
}

interface IProps extends IComponentProps {
  competition: ICompetition;
  rounds: Pairing[][];
}


class Dashboard extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      page: "",
      roundId: "1"
    };
  }

  render():ReactNode {
    const { match, competition, rounds} = this.props;
    const {roundId} = this.state;

    if(!(competition && rounds)) {
      return (<>Loading...</>);
    }

    let p = Object.assign({}, this.props);
    p.match.params.roundId = roundId;

    let round = Number(roundId);
    return (
      <>
        <Typography variant="h5">
            {competition.title}
        </Typography>
        <Typography variant="h6">
            Round {roundId}
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <RoundPairings roundId={round} competitionId={competition.id}/>
          </Grid>
          <Grid item xs={6}>
            <Standings roundId={round} competitionId={competition.id}/>
          </Grid>
          <Grid item xs={6}>
            <RoundPairings roundId={round} competitionId={competition.id}/>
          </Grid>
          <Grid item xs={6}>
            <Standings roundId={1} competitionId={competition.id}/>
          </Grid>
        </Grid>

      </>
    );
  }
}

export default compose<IProps, IProps>(
    firestoreConnect((props:IProps) =>                                
            [ { collection: `competitions`,
                doc: props.match.params.competitionId,
                subcollections:[{
                    collection: "rounds",
                    storeAs: "rounds"        
                }],
            },
            {
              collection: `competitions`,
              doc: props.match.params.competitionId,
              subcollections:[{
                collection: "players",
                storeAs:"players"
              }],
            },
            { collection: `competitions`,
                doc: props.match.params.competitionId,
                storeAs:"competition"
            }
            ]),
      connect(({ firestore: { data, ordered } }: any, props:IProps) => {
        return {
          rounds: ordered.rounds,
          competition: ordered.competition ? ordered.competition[0] : null
        };
      })
)(Dashboard);
