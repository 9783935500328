import ICompetitionManager, { Player } from "./ICompetitionManager";
import { ICompetition } from "../../models/Competition";
import { Pairing } from "../../models/Pairing";
import { IRenderer } from "../../render/BaseRenderer";
import { IRaport, Raports } from "../../common/Raports";

   /**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
    function shuffle(a:any[]) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }


export default abstract class BaseCompetitionManager implements ICompetitionManager {
    
    availableRaports(): IRaport[] {
        return [Raports.pairings, Raports.standings];
    }

    abstract allRounds: boolean;
    abstract defaultRankings: string[];
    abstract getRoundCount(): number;

    abstract createRenderer(competition: ICompetition, round: number): IRenderer;

    abstract roundsFromPlayers(players: number): number;

    createPairings(competition: ICompetition, rounds: Pairing[][], players: {[id:string]: Player}, forRound: number): Pairing[]
    {
        let pairings = this._createPairings(competition, rounds, players, forRound);

        let requiredLines = pairings.filter(p => p.player1 && p.player2).length;

        pairings = pairings.sort((a, b)=>{
            if(a.player1 && a.player2) {
                return b.player1 && b.player2? 0: -1;
            } else {
                return b.player1 && b.player2? 1: 0;
            }
        });


        var lanes = new Array(requiredLines);
        var i = 0;
        while(i < requiredLines) lanes[i++] = i-1;

        if(competition.randomLine) {
            shuffle(lanes);
        }
        lanes.forEach((m:number, i:number) => {
            pairings[i].spot = m + competition.firstLine;
            
        });
        
        /* // do not sort by spot - it may affect some rendering. Sort before display if needed
        pairings = pairings.sort((a, b)=>{
            if(a && b && a.spot && b.spot) {
                return a.spot - b.spot;
            } else if(a && a.spot) {
                return -1;
            } else if(b && b.spot) {
                return 1;
            } else {
                return 0;
            }
        });*/
        return pairings;
    }

    winPoints(r1?:string, r2?:string) : number|null{
        let p1:number|null = r1? Number(r1):null;
        let p2:number|null = r2? Number(r2):null;

        if(p1 == null || p2 == null) {
          return null;
        } else if(p1 > p2) {
          return 1;
        } else if(p2 > p1) {
          return 0;
        } else {
          return  0.5;
        }

    }
    
    player1WinPoints(p: Pairing) : number|null {
        return this.winPoints(p.result1, p.result2);        
    }


    player2WinPoints(p: Pairing) : number|null {
        return this.winPoints(p.result2, p.result1);        
    }


    abstract standings(competition: ICompetition, rounds: Pairing[][], players: {[id:string]: Player}, afterRound: number): Player[];

    protected abstract _createPairings(competition: ICompetition, rounds: Pairing[][], players: {[id:string]: Player}, forRound: number): Pairing[];
}
