import firebase, { firestore } from "firebase/app";
import { Player } from "../models/Pairing";
import { CollectionReference, DocumentData } from "@firebase/firestore-types";

export function updatePlayer(competitionId:string, player:Player):any {

  return async (dispatch:any, getState:any, {getFirestore}:any) => {
    // make async call to database
    const firestore:firestore.Firestore = getFirestore();
    const currentUser:firebase.User|null = firebase.auth().currentUser;
    let playerId: string = player.id;

    let entity: DocumentData = {};

    for (const field in player) {
      if(field !== "id") {
        entity[field] = player[field];
      }
    }
    entity.owner = currentUser && currentUser.uid;

    const ref:CollectionReference = firestore.collection(`competitions/${competitionId}/players/`);

    if(playerId) {
      await ref.doc(playerId).set(entity);
    } else if(player.name && player.name.trim()) {
      await ref.add(entity);
    }
  };
}

export function deletePlayer(competitionId:string, playerId:string):any {

  return async (dispatch:any, getState:any, {getFirestore}:any) => {
    const firestore:firestore.Firestore = getFirestore();

    const ref:CollectionReference = firestore.collection(`competitions/${competitionId}/players/`);

    await ref.doc(playerId).delete();
  };
}

export function deleteRound(competitionId:string, roundId:string):any {

  return async (dispatch:any, getState:any, {getFirestore}:any) => {
    const firestore:firestore.Firestore = getFirestore();

    const ref:CollectionReference = firestore.collection(`competitions/${competitionId}/rounds/`);

    await ref.doc(roundId).delete();
  };
}


export const enableFeatureAction = (name:string) => ({
  type: "ENABLE_FEATURE",
  feature: name
});
export const disableFeatureAction = (name:string) => ({
  type: "DISABLE_FEATURE",
  feature: name
});

export const updateFeatureAction = (name:string, enabled:boolean) => ({
  type: "SET_FEATURE",
  feature: name,
  value: enabled
});


/*
  export const showCompetitions = x => ({
    type: "SHOW_COMPETITIONS"
  })

export const REQUEST_COMPETITIONS = "REQUEST_COMPETITIONS"
function requestCompetitions() {
  return {
    type: REQUEST_COMPETITIONS
  }
}

export const RECEIVE_COMPETITIONS = "RECEIVE_COMPETITIONS"
export function receiveCompetitions(docs) {
  return {
    type: RECEIVE_COMPETITIONS,
    cc: docs,
    receivedAt: Date.now()
  }
}

export function fetchCompetitions() {
  return function(dispatch) {
    dispatch(requestCompetitions());


    firebase.firestore().collection("competitions").get()

      .then(
        snapshot  => {
        let docs = snapshot.docs;
        dispatch(receiveCompetitions( docs))
        })
  }
}*/