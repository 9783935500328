import React, { Component, ReactNode } from "react";
import {Button, Grid, Paper, Typography, FormGroup, TextField, Avatar} from "@material-ui/core";
import { Organiser } from "../../models/Organiser";
import firebase from "firebase/app";
import {firestore} from "firebase/app";
import { compose  } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import {connect} from "react-redux";
import {history} from "../../redux/HistoryRouter";

interface IState {
  organiser? :Organiser;
  canContinue?: boolean;
  errors?: any;
  files?: any;
}

interface IProps extends IComponentProps {
  organiser :Organiser;
  location: any;
  auth: any;
}


class OrganiserForm extends Component<IProps, IState> {

  constructor(props:IProps) {
    super(props);
    this.state = {
      organiser: new Organiser(),
      files:{},
      errors:{}
    };
  }

  canEdit(): boolean {
    var user:firebase.User|null  = firebase.auth().currentUser;

    return this.props.organiser && (this.props.organiser.owner === (user && user.uid)) ;
  }

  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> =
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): Promise<void> => {
    const state:any = this.state;
    let target:HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement = e.target;
    if(target.type === "file") {
      let inputTarget: HTMLInputElement = target as HTMLInputElement;
      if(inputTarget && inputTarget.files) {
        let file:File = inputTarget.files[0];
        state.files[inputTarget.name] = file;
        let organiser:Organiser|undefined = this.state.organiser;
        if(organiser) {
          organiser.logoImage =  await this.readFileAsync(file);
          this.setState({organiser});
        }
      }
    } else {
      state.organiser[target.name] = target.value;
    }
    state.errors[target.name] = !Boolean(target.value);

    state.canContinue = !Object.values(state.errors).some(e=>e);

    this.setState(state);

  }

  compress(data:string): string {
    const width:number = 500;
    const height:number = 300;

    const fileName:string = "image.jpg";
    let img:HTMLImageElement = new Image();
    img.src = data;
    img.onload = () => {
                const elem:HTMLCanvasElement = document.createElement("canvas");
                elem.width = width;
                elem.height = height;
                const ctx:CanvasRenderingContext2D|null = elem.getContext("2d");
                if(ctx) {
                // img.width and img.height will contain the original dimensions
                  ctx.drawImage(img, 0, 0, width, height);
                  let url:string = ctx.canvas.toDataURL();
                  console.log(url);
              }
            };
    return "";
  }

  async submit(): Promise<void> {

    console.log("Organiser.submit");
    if(!this.state.organiser) {
      return;
    }
    let organiser:Organiser|null = {... (this.state.organiser)};

    let file:File = this.state.files.logoImage;
    // console.log(await this.checkFileAsync(file));
    // contentBuffer = this.compress(contentBuffer);
    // console.log(contentBuffer);

    const ref:firestore.CollectionReference  = this.props.firestore.collection(`organisers`);

    let user:firebase.User|null = firebase.auth().currentUser;
    if(!this.state.organiser.id && user) {
      organiser.owner = user.uid;
      const res:firestore.DocumentReference  = await ref.add({...organiser});
      this.state.organiser.id = res.id;
    }

    if(file) {
      // let contentBuffer:string|ArrayBuffer|null = await this.readFileAsync(file);
      let logoImageUrl:string = await this.upload(file, `/organisers/${this.state.organiser.id}/logo`, file.type);
      organiser.logoImage = logoImageUrl;
    }
    delete organiser.id;

    console.log("Organiser.update");
    ref.doc(this.state.organiser.id).set({...organiser});

    const {state} = this.props.location;
    if(state && state.goBack) {
      history.goBack();
    }
  }

  readFileAsync(file:File):Promise<string> {
    return new Promise((resolve, reject) => {
      let reader:FileReader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  checkFileAsync(file:File):Promise<boolean> {
    return new Promise((resolve, reject) => {
      let img:HTMLImageElement = new Image();
      img.src = window.URL.createObjectURL( file );
      img.onload = () => {
        let width:number = img.naturalWidth;
        let height:number = img.naturalHeight;

        window.URL.revokeObjectURL( img.src );
        if( width >= 300 && height >= 300 ) {
          resolve( true);
        } else {
          resolve( false);
        }
      };
    });
  }

  async upload(file:File, path:string, contentType:string= "image/jpeg"): Promise<string> {

    return new Promise((resolve, reject) => {
      if(!file) {
        reject("");
      }

      // create the file metadata
      let metadata:object = {
        contentType: contentType
      };

      let storageRef:firebase.storage.Reference = firebase.storage().ref();

      // upload file and metadata to the object 'images/mountains.jpg'
      let uploadTask:firebase.storage.UploadTask = storageRef.child(path).put(file, metadata);

      // listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot:firebase.storage.UploadTaskSnapshot):void {
          // get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress:Number = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log( "'Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is runnin");
              break;
          }
        }, function(error:any):void {

        // a full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // user doesn't have permission to access the object
            break;

          case "storage/canceled":
            // user canceled the upload
            break;

          case "storage/unknown":
          default:
            // unknown error occurred, inspect error.serverResponse
            break;
        }
        reject(error);
      }, function():void {
        // upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL:string):void {
          console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      });
    });
  }

  componentDidUpdate(prevProps:IProps):void {
    if(this.props.organiser !== prevProps.organiser) {
      let organiser:Organiser = this.props.organiser;
      this.setState({
        organiser:{...organiser}
      });
    }
  }

  render(): ReactNode {
    const readOnly:boolean = false;
    const { match, auth } = this.props;
    const { canContinue, errors } = this.state;
    const organiser:Organiser = this.state.organiser || new Organiser();

    if(!isLoaded(auth)) {
      return (<div>Loading ...</div>);
    }

    return (
      <Grid  container direction="row" justify="center">
        <Grid item lg={6} md={8} sm={12}>
        <Paper style={{ padding: 16 }}>
            <Typography variant="h5">
                  Organiser              </Typography>
            <form >
            <FormGroup >
              <TextField required fullWidth
                error={errors.title}
                id="title"
                label="Title"
                name = "title"
                value={organiser.title||""}
                onChange={this.onChange}
                margin="normal"
                style={{ margin: 8 }}
                InputProps={{
                  disableUnderline:readOnly,
                  readOnly: readOnly
                  }}
              />
              <input hidden
                name="logoImage"
                accept="image/*"
                id="raised-button-file"
                type="file"
                onChange={this.onChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" >
                  Upload logo
                </Button>
              </label>
               <Avatar
                style={{width:300, height:300, borderRadius:0, backgroundColor:"transparent" }}
                imgProps={{style: {objectFit:"contain"} }}
                src={organiser.logoImage}
                title="Logo"
              />
              </FormGroup>
              <Button disabled={!canContinue} variant="contained" color="primary" onClick={()=>this.submit()}>Save</Button>
            </form>
            </Paper>
          </Grid>
        </Grid>
    );
  }
}

export default compose<IProps, IProps>(

  connect<any, IProps, IProps>(({ firebase: {auth}, firestore: {ordered}}:any, props:IProps) => {
    let organiser: Organiser|null = null;
    let entity:Organiser = {...ordered.organiser}[0];
    const user:firebase.User|null = firebase.auth().currentUser;

    if( user && entity && user.uid && user.uid === entity.owner) {
      organiser = entity;
    }
    return {
      auth,
      organiser
    };
  }),
  firestoreConnect((props: IProps) =>  {
    const user:any = props.auth;
    return user && user.isLoaded && user.uid? [
        { collection: `organisers`,
          where: ["owner", "==", user.uid],
          storeAs: "organiser"
        },
  ]:[];
  }),

)(OrganiserForm);
