import BaseRenderer from "./BaseRenderer";

import { Pairing } from "../models/Pairing";
import React, { ReactNode, ReactElement } from "react";
import {Avatar} from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import PlayoffsManager from "../algorithms/managers/Playoffs";
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { makeStyles } from "@material-ui/core/styles";
import { BaseCellRenderer } from "./BaseCellRenderer";

export class PdfPlayoffsTableCellRenderer extends BaseCellRenderer<any> {
    createEmptyCell():any {
        return {
            text:" ",
            border: [false, false, false, false]
        };
    }

    createResultCell(value:string):any {
        return {
            border: [false, false, false, true],
            text:value
          };
    }

    createPlaceCell(value:string):any {
        return {
            border: [false, false, false, true],
            text:value
          };
    }

    createPlayerCell(teamName:string):any {
        return {
            border: [false, false, false, true],
            text: teamName
          };
    }

    setRightBorder(cell:any, border:boolean):void {
        cell.border[2] = border;
    }

    renderCells(cells:any[][]):any {
        const widths:string[] = [];
        
        for (let i:number = 0; i < cells[0].length; i ++) {
            widths.push(i % 2? "auto": "*");
        }

        var content:any[] = [
            {
                style: "mainTable",
                layout: {
                    defaultBorder: false,
                },
                table: {
                    widths: widths,
                    body: cells
                }
            }
        ];

        return content;
    }
}

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: '#fff',
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: '#fff',
      backgroundColor: deepPurple[500],
    },
  }));

export class HtmlPlayoffsTableCellRenderer extends BaseCellRenderer<ReactElement> {
    createEmptyCell():ReactElement {
        return (<div style={{}} className="cell">{"\u200b"}</div>);
    }

    createResultCell(value:string):ReactElement {
        return (<div style={{"borderBottom":"1px solid"}} className="cell result">
                    <span className="content">{value}</span>
                </div>);
    }
    
    createPlaceCell(value:string):ReactElement {
        let purple = {
            color: '#fff',
            backgroundColor: deepPurple[500],
          };
        return (<div style={{"borderBottom":"1px solid"}} className="cell place">
    <div className="place">{value}</div>
                </div>);
    }

    createPlayerCell(player:string):ReactElement {
        return (<div style={{"borderBottom":"1px solid"}} className="cell player">
                    <span className="content">{player}</span>
                </div>);
    }

    setRightBorder(cell:ReactElement, border:boolean):void {
        let props: CSSProperties = {backgroundColor: "red"};
        if(!cell) {
            return;
        }
        //cell.props.style.borderRight = props;
        //cell.props.style = cell.props.style || {};
        cell.props.style.borderRight = border?"1px solid":"0px solid";
    }

    renderCells(cols:ReactElement[][]):ReactNode {

        let cells = cols.reduce(function(prev, next) {
            return prev.concat(next);
        });


        let num:number = cols[0].length / 2;
        const table:ReactNode= (<div style={{display: "grid", gridTemplateColumns:"auto min-content ".repeat(num)}}>{cells}</div>);

        return table;
    }
}
/*
export default class PlayoffsRenderer extends BaseRenderer {

    createStandingsHtml(): ReactNode {
        return (new HtmlCellRenderer()).render(this.players, this.allPairings, this.rounds, this.roundId);
    }

    createStandingsPdf(): any[] {
        return (new PdfCellRenderer()).render(this.players, this.allPairings, this.rounds, this.roundId);
    }


}*/
