import BaseCompetitionManager from "./BaseCompetitionManager";
import {Pairing} from "../../models/Pairing";
import { ICompetition } from "../../models/Competition";
import PlayoffsRenderer from "../../render/PlayoffsRenderer";
import { Player } from "./ICompetitionManager";
import { IRenderer } from "../../render/BaseRenderer";
import { MAX_VALUE } from "long";
import { IRaport, Raports } from "../../common/Raports";

export default class PlayoffsManager extends BaseCompetitionManager {
    
    allRounds: boolean = false;
    defaultRankings: string[] = ["PlayoffsRanking", "SeedRanking"];
    
    competition:ICompetition;
    rounds:Pairing[][];
    players:{[id:string]: Player};
    round: number;
    
    constructor(competition:ICompetition, rounds:Pairing[][], players:{[id:string]: Player}, round: number) {
        super();
        this.competition = competition;
        this.rounds = rounds;
        this.players = players;
        this.round = round;
    }

    availableRaports(): IRaport[] {
        let raports = super.availableRaports();
        raports.push(Raports.table);
        return raports;
    }
    
    getRoundCount(): number {
        return this.players?this.roundsFromPlayers(Object.keys(this.players).length):0;
    }
    
    createRenderer(competition: ICompetition, round: number): IRenderer {
        return new PlayoffsRenderer(competition, this.players, this.rounds, round, this.players &&
            this.roundsFromPlayers(Object.keys(this.players).length || 0));
      }

    roundsFromPlayers(players: number): number {
        return players>0?Math.ceil(Math.log(players)/Math.log(2)):0;
    }


    /** */
    groupsFromPlayers(players: number, round: number): number[] {
        
        let groups = [players];
        //console.log(groups);
        for(let r = 1; r < round; r++) {
            let next:number[] = [];
            groups.forEach((val, i) => {
                if (val > 1) {
                    let rounds = this.roundsFromPlayers(val);
                    let g1 = Math.ceil(Math.pow(2, rounds - 1));
                    next.push(g1);
                    next.push(val - g1);
                } else {
                    next.push(val);
                }
    
            });
            groups = next;
            //console.log(groups);
        }
    
        return groups
    }

    _createPairings(competition:ICompetition, rounds: Pairing[][], playersIn: {[id:string]: Player}, forRound: number): Pairing[] {

        let players = playersIn;

        let playersCount = Object.keys(players).length;

        //Object.keys(players).forEach((v, i)=>players[v].id=v);
        let result:Pairing[] = [];
        
        //console.log(forRound);
        let standings = this.standings(competition, rounds, playersIn, forRound-1);
        
        let totalPlayers = playersCount;
        let groups = this.groupsFromPlayers(totalPlayers, forRound);
        let groupStart = 0;
        groups.forEach((groupPlayers)=>{
            let bracket:(number|null)[][] = this.getBracket(groupPlayers);
            //console.log("bracket", bracket);
            bracket.forEach((b)=>{
                let pairing:Pairing = new Pairing();
                if(b[0]) {
                    let p1 :number = groupStart + b[0]-1;
                    let player1:Player = standings[p1];
                    pairing.player1 = player1 ? player1.id :undefined; 
                }
                if(b[1]) {
                    let p2:number = groupStart + b[1]-1;
                    let player2:Player = standings[p2];
                    pairing.player2 = player2 ? player2.id :undefined; 
                }
                result.push(pairing);

            });
            groupStart += groupPlayers;
        });

        result.forEach((m:any, i:number) => {
            m.spot = i + competition.firstLine;
        });

        return result;
    }

    standings(competition:ICompetition, rounds:Pairing[][], players: {[id:string]: Player}, afterRound:number):Player[] {
        let result:Player[] = this.collectSeeds(rounds, players, afterRound);
        return this.sortPlayers(result);
    }

    collectSeeds(rounds:Pairing[][], players: {[id:string]: Player}, afterRound:number):Player[] {
        let result:{[id:string]: Player}= {};

        //console.log("collectSeeds");
        
        for (let key of Object.keys(players)) { 
            result[key] = {... players[key]};
            result[key].id = key;
        }

        for(let round of rounds) {
            for(let pair of Object.values(round)) {
                if(pair.player1 && pair.player2) {
                    let winSeed = Math.min(result[pair.player1].seed||0, result[pair.player2].seed||0);
                    let lostSeed = Math.max(result[pair.player1].seed||Infinity, result[pair.player2].seed||Infinity);
                    
                    if(pair.player1 && pair.player2 && pair.result1 !== undefined && pair.result2 !== undefined) {
                        let result1 = Number(pair.result1);
                        let result2 = Number(pair.result2);

                        if(result1 > result2) {
                            result[pair.player1].seed = winSeed;
                            result[pair.player2].seed = lostSeed;
                        } else if(result1 < result2) {
                            result[pair.player2].seed = winSeed;
                            result[pair.player1].seed = lostSeed;
                        } else {
                            throw "result is even"
                        }
                    }
                }
            }
        }
        
        return Object.values(result);
    }

    sortPlayers(players:Player[]):Player[] {
        return players.sort((p1, p2)=> {
            let pointsDiff:number = 0;
            if(p1.points !== undefined && p2.points !== undefined) {
                pointsDiff = p2.points - p1.points;
            }

            if(!pointsDiff && p1.seed !== undefined && p2.seed !== undefined) {
                return p1.seed - p2.seed;
            }

            return pointsDiff;
        });
    }
    getBracket(participantsCount:number): (number|null)[][] {

        let rounds:number = this.roundsFromPlayers(participantsCount);
        let bracketSize:number = Math.pow(2, rounds);
        let requiredByes:number = bracketSize - participantsCount;

        if(participantsCount < 1) {
            return [];
        } else if(participantsCount === 1) {
            return [[1]];
        }

        let matches:(number|null)[][] = [[1,2]];

        for(let round:number = 1; round < rounds; round++) {
            let roundMatches:(number|null)[][] = [];
            let sum:number = Math.pow(2, round + 1) + 1;

            for(let i:number = 0; i < matches.length; i++) {
                let home:number|null = this.changeIntoBye(matches[i][0], participantsCount);
                let away:number|null = this.changeIntoBye(sum - (matches[i][0]||0), participantsCount);
                roundMatches.push([home, away]);
                home = this.changeIntoBye(sum - (matches[i][1]||0), participantsCount);
                away = this.changeIntoBye(matches[i][1], participantsCount);
                roundMatches.push([home, away]);
            }
            matches = roundMatches;
        }

        return matches;
    }

    changeIntoBye(seed:(number|null), participantsCount:number):number|null {
        return seed !== null && seed <= participantsCount ?  seed : null;
    }
}