import {join_paths} from "../../common/utils";

import React, { Component, ReactNode, ChangeEvent } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import RoundPairings from "./RoundPairings";
import { connect } from "react-redux";
import { compose, withHandlers  } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import firebase from "firebase/app";
// import Algorithm from "../../algorithms/Pairings";
import Standings from "./Standings";
import {Tabs, Tab, IconButton, Paper, Divider, Menu, MenuItem, Hidden, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {MoreVert as MoreVertIcon} from "@material-ui/icons";

import {history} from "../../redux/HistoryRouter";
import { deleteRound } from "../../actions";
import confirmDialog from "../../common/confirmDialog";
import { ICompetition } from "../../models/Competition";
import { Pairing } from "../../models/Pairing";
import ErrorBoundary from "../../common/ErrorBoundary";
import Table from "./Table";

interface IState {
  roundId?: string;
  tab?: number;
  anchorEl?:any;
  redirect?:string;
}
interface IProps extends IComponentProps {
  auth : any;
  competition?: ICompetition;
  rounds:Pairing[][];
  players:{[id:string]: Player};
  dispatch:any;
}

class Rounds extends Component<IProps, IState> {

  manager: ICompetitionManager|null = null;


  constructor(props:IProps) {
    super(props);
   
    var tab :number= this.tabId();
    
    this.state = {
      tab
    };
  }
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    var roundId:string = this.props.match.params.roundId || "1";
    var tab:number = this.tabId();
    const { rounds, competition, players} = this.props;

    console.log("Rounds", tab, this.state.tab, roundId, this.state.roundId, prevProps);
    if(isLoaded( rounds, competition, players) && competition) {
      console.log("Rounds componentDidUpdate");
      this.manager =  competition.getManager(rounds, players);

      if(!this.props.rounds) {
        let target:string = join_paths( this.props.match.url, `../../players`);
        history.replace(target);
      } else if(Number(roundId) > this.props.rounds.length) {
        let target:string = join_paths( this.props.match.url, `/rounds/${Number(roundId)-1}`);
        history.replace(target);
      } else {
        if(roundId !== this.state.roundId) {
          this.setState({tab, roundId});
        }
      }
    }
  }

  tabId(): number {
    let path = history.location.pathname;
    let tab = path.substring(path.lastIndexOf('/') + 1);
    console.log("tabid", tab);
    switch(tab) {
      case "pairings": return 0;
      case "standings": return 1;
      case "table": return 2;
      default: return 0;
    }
    return history.location.pathname.endsWith("standings")?1:0;
  }

  canEdit = ():boolean => {
    var user:firebase.User|null = firebase.auth().currentUser;
    return this.props.competition && (this.props.competition.owner === (user && user.uid)) || false;
  }

  onShowRoundClick = (e:React.MouseEvent<HTMLLIElement, MouseEvent>, round:number) => {
    var target:string = join_paths( this.props.match.url, `${round}`);
    history.push(target);
  }

  changeRound = (e:React.MouseEvent<HTMLLIElement, MouseEvent>, tab:number) => {
    var target:string = join_paths( this.props.match.url, `${tab+1}`);
    history.push(target);
    this.setState({ tab});
  }

  showMoreMenu = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target:EventTarget = e.currentTarget;
    this.setState({anchorEl:target});
  }

  closeMoreMenu = () => {
    this.setState({anchorEl:null});
  }

  deleteRound = (e:React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    this.closeMoreMenu();
    const {competition, match} = this.props;
    const {roundId} = this.props.match.params;

    const options: any = {
      title: "Delete round?",
      message: `Do you wish to delete round ${roundId}? Pairing and results of this round will be lost`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await this.props.dispatch(deleteRound(competition!.id||"", roundId||""));
              var target:string;
              if(Number(roundId) > 1) {
                target = join_paths(match.url,  `../${Number(roundId)-1}/pairings`);
              } else {
                target= join_paths(match.url,  `../../players`);
              }
              history.replace(target);
            } catch (error) {
              alert(`Error deleting round ${roundId}`);
              console.error(`Error deleting round ${roundId}: `, error);
            }
          }
        },
        {
          label: "No",
        }
      ]
    };

    confirmDialog(options);

  }

  handleChange = (event:ChangeEvent<{}>, tab: number) => {

    var tabName:string = "";
    switch(tab) {
      case 0:
        tabName = "pairings";
        break;
      case 1:
        tabName = "standings";
        break;
      case 2:
        tabName = "table";
        break;
      default:
        tabName = "pairings";
    }

    const { match } = this.props;
    var target:string = join_paths(match.url,  tabName);
    console.log("target: " + target);
    history.push(target);
    this.setState({ tab });
  }

  render():ReactNode {
    if(!(this.props.competition && this.manager)) {
     return null;
    }

      const { match, rounds} = this.props;
      const {anchorEl, roundId} = this.state;

      let canDeleteRound:boolean = this.canEdit() && rounds && !(rounds[Number(roundId)+1]);
      const totalRounds = this.manager.getRoundCount();

      let renderTabs:ReactNode[] = [];
      this.manager.availableRaports().forEach(function (raport:IRaport, index:number) {
        renderTabs.push( <Tab label={raport.name} key={"tab"+index} />);
      });

      return (
        <>
        <Hidden xsDown>
          <Box p={1}  fontWeight="fontWeightBold">
            <Typography variant="subtitle2">Round {roundId} of {totalRounds}</Typography>
          </Box>
        </Hidden>
            <Paper>
            <Box display="flex" >
              <Box flexGrow={1}>
                <Tabs value={this.state.tab}
                    style={{marginBottom:"4px"}}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange} >
                      {renderTabs}
                </Tabs>
              </Box>
                <Box>
                  <Hidden xsDown>
                    <Route exact path={join_paths(match.path, "pairings")} render={(props) => <RoundPairings {...props} toolbar roundId={props.match.params.roundId}/>}/>
                    <Route exact path={join_paths(match.path, "standings")} render={(props) => <Standings {...props} toolbar roundId={props.match.params.roundId}/>}/>
                    <Route exact path={join_paths(match.path, "table")} render={(props) => <Table {...props} toolbar roundId={props.match.params.roundId}/>}/>
                  </Hidden>
                  <IconButton  onClick={this.showMoreMenu} ><MoreVertIcon /></IconButton>
                </Box>
            </Box>
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMoreMenu}>
                <Route exact path={join_paths(match.path, "pairings")} render={(props) => <RoundPairings {...props} menu roundId={props.match.params.roundId}/>}/>
                <Route exact path={join_paths(match.path, "standings")} render={(props) => <Standings {...props} menu roundId={props.match.params.roundId}/>}/>
                <Route exact path={join_paths(match.path, "table")} render={(props) => <Table {...props} menu roundId={props.match.params.roundId}/>}/>
                <Divider/>
              {this.canEdit()&&
                <MenuItem disabled={!canDeleteRound} onClick={this.deleteRound}>Delete round {roundId}</MenuItem>
              }
            </Menu>
            <Divider/>
              <div >
                <Switch location={history.location}>
                  <Route exact path={join_paths(match.path, "pairings")} 
                      render={(props) => 
                      <ErrorBoundary>
                        <RoundPairings  {...props} roundId={match.params.roundId} competitionId = {match.params.competitionId} />
                      </ErrorBoundary>
                      }/>
                  <Route exact path={join_paths(match.path, "standings")} render={(props) => 
                      <ErrorBoundary>
                        <Standings {...props} roundId={props.match.params.roundId} />
                      </ErrorBoundary>
                    }  />
                  <Route exact path={join_paths(match.path, "table")} render={(props) => 
                      <ErrorBoundary>
                        <Table {...props} roundId={props.match.params.roundId} />
                      </ErrorBoundary>
                    }  />
                  <Redirect exact path={join_paths(match.path)} to={join_paths(match.path, "pairings")} />
                </Switch>
            </div>
            </Paper>
        </>
      );
    }
  }

  export default compose<IProps, IProps>(
    firestoreConnect((props: IProps) =>
                              [ { collection: `competitions`,
                                     doc: props.match.params.competitionId,
                                      subcollections:[{
                                        collection: "rounds"
                                    }],
                                    storeAs:"rounds"
                                  },
                                  {
                                    collection: `competitions`,
                                    doc: props.match.params.competitionId,
                                    subcollections:[{
                                      collection: "players"
                                    }],
                                    storeAs:"players",
                                  },
                                    { collection: `competitions`,
                                     doc: props.match.params.competitionId,
                                     storeAs: "competition"
                                    }
                                  ]),
    connect(({ firestore: { data, ordered } }:any, props:IProps) => ({
      players: data.players,
      competition: ICompetition.fromFirestore(ordered.competition[0]),
      rounds: data.rounds
    }))
  )(Rounds);
