import React, {Component, ReactNode} from "react";
import { compose} from "recompose";
import { firestoreConnect, isLoaded} from "react-redux-firebase";
import { connect } from "react-redux";
import { ICompetition } from "../../models/Competition";
import { Pairing, Pairings } from "../../models/Pairing";
import { Player } from "../../algorithms/managers/ICompetitionManager";
import BaseRenderer from "../../render/BaseRenderer";

interface IState {
    roundId?: number;
    wins?: Map<string, number>|null;
  }
  
  interface IInputProps {
    roundId?: number;
    competitionId?: string;
  }
  
  interface IProps extends IComponentProps, IInputProps {
    competition: ICompetition;
    players:{[id:string]: Player};
    rounds:Pairing[][];
    currentPairings:Pairing[];
    match:any;
  }


class StreamStandings extends React.Component<IProps, IState>  {
    constructor(props: IProps) {
        super(props);
  
      }

    render(): ReactNode {
        const {competition, currentPairings, players, rounds, roundId} = this.props;
        
        if(!isLoaded(competition, currentPairings, players, rounds)) {
          return <></>;
        }

        let renderer = competition.getManager(rounds, players).createRenderer(competition, roundId);
        var table = renderer.createStandingsStream();


        return (
        <div className="stream-container">
            <div className="header">
            <h1>{this.props.competition.title}</h1>
            <h2>Round {roundId}. Standings</h2>
            </div>
            {table}
            <div className="powered-by">allresults.live</div>
        </div>);
    }
}

export default compose<IProps, IInputProps>(
    firestoreConnect((props: IProps) =>
      [{ collection: `competitions`,
         doc: props.competitionId,
         subcollections:[{
              collection: "rounds"
          }],
          storeAs:"rounds"
        },
        {
          collection: `competitions`,
          doc: props.competitionId,
          subcollections:[{
            collection: "players"
          }],
          storeAs:"players",
        },
          { collection: `competitions`,
           doc: props.competitionId,
           storeAs: "competition"
          }
        ]),
    connect(({ firestore: { data, ordered } }: any, props:IProps) => {
      let roundPairings:Pairings|null = ordered.rounds?(props.roundId ? ordered.rounds[props.roundId]: ordered.rounds.slice(-1)[0]) : null;
  
      return {
        roundId: ordered.rounds?.length,
        rounds: data.rounds,
        players: data.players,
        currentPairings: roundPairings,
        competition: ICompetition.fromFirestore( ordered.competition?.[0])
      };
    })
  )(StreamStandings);
