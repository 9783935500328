import { firestore } from "firebase";

export class Pairing {
    public constructor(init?:Partial<Pairing>) {
        Object.assign(this, init);
    }
    [column:string]: any;
    key:string="";
    player1?:string;
    player2?:string;
    result1?:string;
    result2?:string;
    show:boolean = true;
    spot?:number;
}

export class Pairings {
    constructor() {
        const now:Date = firestore.Timestamp.now().toDate();
        this.created = now;
        this.modified = now;
    }
    [column:string]: Pairing|Date|undefined|string;

    created?:Date;
    modified?:Date;
    owner?:string;
}

export class Player {
    [column:string]: any;
    key?:string;
    name:string="";
}