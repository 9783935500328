
import urljoin from "url-join";

import React, { Component, ReactNode } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {Grid} from "@material-ui/core";
import Create from "./Create";
import Show from "./Show";
import Competitions from "./List";


interface IState {
}

interface IProps extends IComponentProps {
}

class Index extends Component<IProps, IState> {

  render():ReactNode {
    const { match} = this.props;
    return (
      <Grid container justify="center">
        <Grid item xl={8} lg={10} xs={12}>
          <Switch>
            <Route path={urljoin(match.path,`create`)} component={Create} />
            <Route path={urljoin(match.path, ":competitionId")} component={Show}/>
            <Route exact path={`${match.path}`} component={Competitions} />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default Index;