const confg:any = process.env.NODE_ENV === "production"?
{
    sentry: {
        dsn:"https://f43877af94d04a2ba71b469e1bd6376d@o431373.ingest.sentry.io/5382283",
    }
}
:
{
    sentry: {
        dsn:"https://516670e414fd46b289cfba215f22a769@o431373.ingest.sentry.io/5382294",
    }
};

export default confg;