/*
https://stackoverflow.com/questions/15338610/dynamically-loading-a-typescript-class-reflection-for-typescript/15339429#15339429

var newInstance = Object.create(window[className].prototype);
newInstance.constructor.apply(newInstance, instanceparameters);
return newInstance;
*/

import { Player } from "../managers/ICompetitionManager";

export class Pairing {
    constructor( readonly home: string,
                 readonly guest: string,
                 readonly home_points: number,
                 readonly guest_points: number) {
    }
}

export class Participant {
    constructor(
        readonly id: string) {
    }
    pairings?:Pairing[];
}

export class Round {
    constructor(readonly pairings: Array<Pairing>) {
    }
}

export class Ranking {
    constructor( readonly participant: string,
                 readonly ranking: Number) {}
}


export interface IRanking {
    name: string;
    shortName: string;
    visible: boolean;

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number>;
}



export class PointsRanking implements IRanking {
    shortName: string = "Pts.";
    name: string = "Total points";
    visible: boolean = true;

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();

        for (let round of rounds) {
            for (let pair of round.pairings) {
                if(pair.home_points) {
                    let points: number = rankings.get(pair.home)||0;
                    points += Number(pair.home_points);
                    rankings.set(pair.home, points);
                }
                if(pair.guest && pair.guest_points) {
                    let points: number = rankings.get(pair.guest)||0;
                    points += Number(pair.guest_points);
                    rankings.set(pair.guest, points);
                }
            }
         }

        return rankings;
    }
}

export class PointsDiffRanking implements IRanking {
    shortName: string = "Diff.";
    name: string = "The difference between the points won and points lost";
    visible: boolean = true;

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();

        for (let round of rounds) {
            for (let pair of round.pairings) {
                let points: number = rankings.get(pair.home)||0;
                let diff: number = Number(pair.home_points||0) - Number(pair.guest_points||0);
                points += diff;
                rankings.set(pair.home, points);

                if(pair.guest) {
                    let points: number = rankings.get(pair.guest)||0;
                    points -= diff;
                    rankings.set(pair.guest, points);
                }
        }
         }

        return rankings;
    }
}

export class WinsRanking implements IRanking {
    shortName: string = "Wins";
    name: string = "Number of Wins";
    visible: boolean = true;

    constructor (readonly winPoints: number = 1,
                 readonly losePoints: number = 0,
                 readonly tiePoints: number = 0.5,
                 readonly byePoints: number = 1) {
                 }

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();

        for (let round of rounds) {
            for (let pair of round.pairings) {
                let points: number = rankings.get(pair.home)||0;
                if(pair.guest) {
                    if(pair.home_points!=null && pair.guest_points != null) {

                        let guestPoints: number = rankings.get(pair.guest)||0;
                        let home_points: number|null = Number(pair.home_points||(pair.guest?this.byePoints:null));
                        let guest_points: number|null = Number(pair.guest_points||(pair.home?this.byePoints:null));

                            
                        if(home_points > guest_points) {
                            points += this.winPoints;
                            guestPoints += this.losePoints;
                        } else if( home_points < guest_points) {
                            points += this.losePoints;
                            guestPoints += this.winPoints;
                        } else {
                            points += this.tiePoints;
                            guestPoints += this.tiePoints;
                        }
                        rankings.set(pair.guest, guestPoints);
                    }
                } else {
                    points += this.byePoints;
                }
                rankings.set(pair.home, points);
            }
         }

        return rankings;
    }
}

export class SeedRanking implements IRanking {
    shortName: string = "Seed ranking";
    name: string = "Seed ranking";
    visible: boolean = false;

    constructor (readonly winPoints: number = 1,
                 readonly losePoints: number = 0,
                 readonly tiePoints: number = 0.5,
                 readonly byePoints: number = 1) {
                 }

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();

        for (let k of Object.keys(participants)) {
            let participant = participants[k];
            rankings.set(k, -Number(participant.seed));
         }

        return rankings;
    }
}

export class PlayoffsRanking implements IRanking {
    shortName: string = "Playoff";
    name: string = "Playoffs ranking";
    visible: boolean = false;


    constructor (readonly winPoints: number = 1,
                 readonly losePoints: number = 0,
                 readonly tiePoints: number = 0.5,
                 readonly byePoints: number = 1) {
                 }

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();

        for (let round of rounds) {
            for (let pair of round.pairings) {
                let homeTotalPoints: number = pair.home && rankings.get(pair.home)||0;
                let guestTotalPoints: number = pair.guest && rankings.get(pair.guest)||0;

                let home_points: number = pair.home && Number(pair.home_points||0) || 0;
                let guest_points: number = pair.guest && Number(pair.guest_points||0) || 0;

                homeTotalPoints *= 2;
                guestTotalPoints *= 2;

                if(home_points > guest_points) {
                    homeTotalPoints += pair.guest ? this.winPoints : this.byePoints;
                    guestTotalPoints += pair.home ? this.losePoints : this.byePoints;
                } else if( home_points < guest_points) {
                    homeTotalPoints +=  pair.guest ?this.losePoints : this.byePoints;
                    guestTotalPoints += pair.home ? this.winPoints : this.byePoints;
                } else {
                    homeTotalPoints +=  pair.guest ?this.tiePoints : this.byePoints;
                    guestTotalPoints += pair.home ? this.tiePoints : this.byePoints;
                }

                if(pair.guest) {
                    rankings.set(pair.guest, guestTotalPoints);
                }
                if( pair.home) {
                    rankings.set(pair.home, homeTotalPoints);
                }
            }
         }

        return rankings;
    }
}

export class BuchholzRanking implements IRanking {
    shortName: string = "Bhz.";
    name: string = "Buchholz points – the total wins of all the opponents";
    visible: boolean = true;


    constructor (readonly winPoints: number = 1,
                 readonly losePoints: number = 0,
                 readonly tiePoints: number = 0.5,
                 readonly byePoints: number = 1) {
                 }

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();

        let rank: Map<string, number> = new WinsRanking().rankings(participants, rounds);


        for (let round of rounds) {
            for (let pair of round.pairings) {
                let points: number = rankings.get(pair.home)||0;
                if(pair.guest) {
                    let guestPoints: number = rankings.get(pair.guest)||0;

                    guestPoints += rank.get(pair.home)||0;
                    points += rank.get(pair.guest)||0;

                    rankings.set(pair.guest, guestPoints);
                }
                rankings.set(pair.home, points);
            }
         }

        return rankings;
    }
}

export class FineBuchholzRanking implements IRanking {
    shortName: string = "FBhz.";
    name: string = "FIne Buchholz – the total of Buchholz points of all the opponents";
    visible: boolean = true;


    constructor (readonly winPoints: number = 1,
                 readonly losePoints: number = 0,
                 readonly tiePoints: number = 0.5,
                 readonly byePoints: number = 1) {
                 }

    rankings( participants: {[id:string]: Player},
              rounds: Array<Round>): Map<string, number> {
        let rankings: Map<string, number> = new Map<string, number>();
        let rank:Map<string, number> = new BuchholzRanking().rankings(participants, rounds);

        for (let round of rounds) {
            for (let pair of round.pairings) {
                let points: number = rankings.get(pair.home)||0;
                if(pair.guest) {
                    let guestPoints: number = rankings.get(pair.guest)||0;

                    guestPoints += rank.get(pair.home)||0;
                    points += rank.get(pair.guest)||0;

                    rankings.set(pair.guest, guestPoints);
                }
                rankings.set(pair.home, points);
            }
         }

         return rankings;
    }
}


export const Rankings:{[id:string]: Function} = {
    PointsRanking,
    PointsDiffRanking,
    WinsRanking,
    BuchholzRanking,
    FineBuchholzRanking,
    PlayoffsRanking,
    SeedRanking
};
// var c= new Rankings["PointsRanking"]();
