import BaseRenderer from "./BaseRenderer";
import { ICompetition } from "../models/Competition";
import { Player } from "../algorithms/managers/ICompetitionManager";
import { Pairing } from "../models/Pairing";
import React, {ReactNode} from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { HtmlCellBuilder, ICellBuilder, PdfCellBuilder, Result } from "./BaseCellRenderer";




export default class RoundRobinRenderer extends BaseRenderer {

  landscapeTable():boolean { return true;}


  createPlayerRow(playerId: string, player:Player, index:number, values: Result[], cellBuilder:ICellBuilder) {
    let cells = [
      cellBuilder.createCell({key:"num", className:"rr-cell", pdfStyle:"", value:`${index+1}`}),
      cellBuilder.createCell({key:"name", className:"rr-player", pdfStyle:"", value:player.name})
    ];
    
    for( let i = 0; i < Object.keys(this.players).length; i++) {
      let style = (i==index)?"unused":"cell";

      let result = values?.[i];
      let value = cellBuilder.createResult({result:result});
      cells.push(cellBuilder.createCell({key:`${i+1}`, className:`rr-cell ${style}`, pdfStyle:style, value:value}));

    }
    this.ranks.forEach((rank, i) => {
      let r = rank.get(playerId);
      cells.push(cellBuilder.createCell({key:this.rankings[i].shortName, className:`rr-cell`, pdfStyle:"", value:r}));
    });

    cells.push(cellBuilder.createCell({key:"place", className:`rr-cell`, pdfStyle:"", value:this.places.get(playerId)}));

    return cells;
  }

    createHeaderCells(cellBuilder:ICellBuilder): React.ReactNode[] {
        let headers = [
          cellBuilder.createCell({key:"#",  pdfStyle:"tableHeader", value:"#"}),
          cellBuilder.createCell({key:"name", className:"rr-cell", pdfStyle:"tableHeader", value:"Player"}),
        ];

        for( let i = 0; i < Object.keys(this.players).length; i++) {
            headers.push(cellBuilder.createCell({key:`${i+1}`, className:"rr-cell", pdfStyle:"tableHeader", value:`${i+1}`}));
        }
        this.rankings.forEach((r) => {
          headers.push(cellBuilder.createCell({key:r.shortName, className:"rr-cell", pdfStyle:"tableHeader", value:r.shortName}));
        });
        headers.push(cellBuilder.createCell({key:"Place", className:"rr-cell", pdfStyle:"tableHeader", value:"Place"}));

        return headers;
      }

      createRows(cellBuilder:ICellBuilder): any[] {
        let rows: any[] = [];
        let numbers:{[id:string]:number} = {};
        Object.keys(this.players).forEach((id:string,i:number) => {
          numbers[id] = i;
        });
  
        let n = Object.keys(this.players).length;
        let values: Result[][] = new Array(n).fill(false).map(() => new Array(n).fill(null));
        let manager = this.competition.getManager(this.allPairings, this.players);
  
        for(let r = 1; r <= this.roundId; r ++) {
          let pairings = this.allPairings[r];
          for (let i in pairings) {
            let p = pairings[i];
            if(p.player1 && p.player2) {
              let winPoints1 = manager.player1WinPoints(p);
              let winPoints2 = manager.player2WinPoints(p);
              values[numbers[p.player1]][numbers[p.player2]] = new Result(p.result1||"", p.result2||"", `${winPoints1==null?" ":winPoints1}`);
              values[numbers[p.player2]][numbers[p.player1]] = new Result(p.result2||"", p.result1||"", `${winPoints2==null?" ":winPoints2}`);
            }
            
          }
        }

        Object.keys(this.players).forEach((key:string, i)=>{
          let player = this.players[key];
          rows.push(
            cellBuilder.createRow({
                key:key,
                cells: this.createPlayerRow(key, player, i, values[i], cellBuilder)
              })
          );
        });
        return rows;
      }

      createTableHtml(): React.ReactNode {
        var cellBuilder = new HtmlCellBuilder();

        let headers = this.createHeaderCells(cellBuilder);
        let rows:ReactNode[]=this.createRows(cellBuilder);

        
        return (    
          <TableContainer component={Paper}>
            <Table className={" "} size="small" aria-label="a dense table">
              <TableHead key="head">
                <TableRow>
                    {headers}
                </TableRow>
              </TableHead>
              <TableBody key="body">
                {rows}
              </TableBody>
            </Table>
          </TableContainer>
            
            );
          }
          
          createTablePdf(): any[] {
            /*  const {wins, players, competition} = this;
            const pairings:Pairing[]|null = this.currentPairings;
            
            // headers ---------------------------------------
            let headers = [{text:"#", style:"tableHeader"},
            {text:"Name", style:"tableHeader"}
          ];
          let widths = ["auto", "*"];
          
      for( let i = 0; i < Object.keys(this.players).length; i++) {
        headers.push({text:`${i+1}`, style:"tableHeader"});
        widths.push("auto");
      }
      this.rankings.forEach((r) => {
        headers.push({text:r.shortName, style:"tableHeader"});
        widths.push("auto");
      });
      headers.push({text:"Place", style:"tableHeader"});
      widths.push("auto");
      
      
      // body ---------------------------------------
      let numbers:{[id:string]:number} = {};
      Object.keys(this.players).forEach((id:string,i:number) => {
        numbers[id] = i;
      });
      
      let n = Object.keys(this.players).length;
      let values: Result[][] = new Array(n).fill(false).map(() => new Array(n).fill(null));
      let manager = this.competition.getManager(this.allPairings, this.players);
      
      for(let r = 1; r <= this.roundId; r ++) {
        let pairings = this.allPairings[r];
        for (let i in pairings) {
          let p = pairings[i];
          if(p.player1 && p.player2) {
            let winPoints1 = manager.player1WinPoints(p);
            let winPoints2 = manager.player2WinPoints(p);
            values[numbers[p.player1]][numbers[p.player2]] = new Result(p.result1||"", p.result2||"", `${winPoints1==null?" ":winPoints1}`);
            values[numbers[p.player2]][numbers[p.player1]] = new Result(p.result2||"", p.result1||"", `${winPoints2==null?" ":winPoints2}`);
          }
          
        }
      }
      
      let rows:ReactNode[]=[];
      
      Object.keys(this.players).forEach((key:string, i)=>{
        let player = this.players[key];
        rows.push(this.createPlayerRow(key, player, i, values[i]));
      });
      */
     var cellBuilder = new PdfCellBuilder();
     
     let rows=this.createRows(cellBuilder);
     let headers = this.createHeaderCells(cellBuilder);
     let widths = Array(headers.length).fill("auto");
     widths[1] = "*";
     
     console.log("rowa", rows);
     let tableBody:any[] = [headers];

     rows.forEach(row => {
       tableBody.push(row);
     });
     console.log("table", tableBody);
     // combine ---------------------------------------
     let content:any[] = [
       {
         style: "mainTable",
         table: {
           widths: widths,
           body: tableBody
          }
        }
      ];
      
      return content;
    }
    
  }
  