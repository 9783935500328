import React, {Component, ReactNode} from "react";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory, History } from "history";

export const history:History = createBrowserHistory();

interface IProps  {
  children: any;
}
interface IState {
}

class HistoryRouter extends Component<IProps, IState> {

  constructor(props:IProps) {
      super(props);
    /*
      console.log('initial history is: ', JSON.stringify(history, null,2))
      history.listen((location, action)=>{
        console.log(
          `The current URL is ${location.pathname}${location.search}${location.hash}`
        )
        console.log(`The last navigation action was ${action}`, JSON.stringify(history, null,2));
      });*/
    }

  render():ReactNode {
    return ( <ConnectedRouter history={history}>
      {this.props.children}
      </ConnectedRouter>
      );
  }
}

export default HistoryRouter;