import "./App.css";

import urljoin from "url-join";

import React, {Component, ReactNode} from "react";
import AppBar from "@material-ui/core/AppBar";

import {Button, Divider, List, Drawer, Hidden, CssBaseline, IconButton,
        ListItem, ListItemIcon, ListItemText, Toolbar, Menu, MenuItem}  from "@material-ui/core";

import { Menu as MenuIcon, Home as HomeIcon} from "@material-ui/icons";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch, Redirect} from "react-router-dom";

import Competitions  from "./components/competitions/index";
import CompetitionMenu from "./components/competitions/CompetitionMenu";
import About  from "./About";
import OrganiserForm from "./components/organiser/OrganiserForm";

import { connect } from "react-redux";
import { compose } from "recompose";
import { firestoreConnect } from "react-redux-firebase";
import {history} from "./redux/HistoryRouter";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import initFunctions from "./AppInit";

import moment from "moment";

import firebase from "firebase/app";
import {
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
/*  IfFirebaseAuthedAnd,*/
  IfFirebaseUnAuthed
} from "@react-firebase/auth";
import { ICompetition } from "./models/Competition";
import Login from "./components/Login";

const drawerWidth:Number = 240;

const styles:any = (theme:any) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
});

interface IState {
  mobileOpen?: boolean;
  open?: boolean;
  anchorEl?:any;
}
interface IProps extends IComponentProps {
  classes?:any;
  theme?:any;
  competition?:ICompetition;
  container?:any;
}

class App extends Component<IProps, IState> {

  state:IState = {
    mobileOpen: false,
    open:false
  };

  componentDidMount= ()=>{
    initFunctions.forEach(f=>f());
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  handleDrawerClose = () =>  {
    this.setState({open: false});
  }

  showProfileMenu = (e:React.MouseEvent) => {
    this.setState({anchorEl:e.currentTarget&&e.currentTarget.parentNode});
  }

  logout = (e:React.MouseEvent) => {
    firebase.auth().signOut();
    this.closeProfileMenu();
  }

  organiserForm = (e:React.MouseEvent) => {
    history.push("/organiser");
    this.closeProfileMenu();
  }

  closeProfileMenu = () => {
    this.setState({anchorEl:null});
  }

  onMenuClick = (e:any) => {
    history.push(e);
  }

  onLoginClick = () => {
    let current = window.location.pathname;
    let url = `/login/?next=${current}`;
    history.push(url);
  }

  render(): ReactNode {
    const { classes, theme, match } = this.props;
    var { competition} = this.props;
    const anchorEl:HTMLElement = this.state.anchorEl;

    const drawer:ReactNode = (
      <>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button id="competitions"  onClick={()=>this.onMenuClick("/competitions")}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={"All Competitions"} />
            </ListItem>
        </List>
        <Divider />
        <Route path="/competitions/:competitionId" render={props => <CompetitionMenu competition={competition} {...props} />} />
        <Divider />
        {/*<Calendar 
          //tileContent={this.tileContent}
          tileClassName={this.tileClassName}
        />*/}
        <Divider />
        <List>
          <ListItem>
            <Button  color="inherit" onClick={() => history.push("/about")}>
              About
            </Button>
          </ListItem>
          <IfFirebaseAuthed>
            {() => (
              <ListItem>
                <Button id="feedbackForum" color="primary" onClick={()=>(window as any)._urq.push(["Feedback_Open"])}>
                    Feedback
                </Button>
              </ListItem>
            )}
          </IfFirebaseAuthed>
        </List>

      </>
    );

    const location:any = this.props.history.location;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit"  className={classes.grow} noWrap>
              All Results
            </Typography>
            <Hidden smDown>
              <Button  color="inherit" onClick={() => history.push("/about")}>
                  About
              </Button>
            </Hidden>
            <div>
              {/*}
            <FirebaseAuthConsumer>
              {({ user, providerId }) => {
                return (
                  <span>
                    {user?user.displayName:""}
                  </span>
                );
              }}
            </FirebaseAuthConsumer>

                              {async () => {
                    try{
                        const googleAuthProvider:firebase.auth.GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
                        const facebookAuthProvider:firebase.auth.FacebookAuthProvider = new firebase.auth.FacebookAuthProvider();
                        await firebase.auth().signInWithPopup(facebookAuthProvider);
                    } catch(e) {
                      alert(e.message);
                    }
                       }}

            */}
            <IfFirebaseUnAuthed>
                {() => (
                  <span>
                  <Button color="inherit" onClick={this.onLoginClick}>
                    Login
                  </Button>
                  </span>
                )}
              </IfFirebaseUnAuthed>
              <IfFirebaseAuthed>
                {(authState:AuthEmission) => (
                <>
                  <Button  color="inherit" onClick={this.showProfileMenu}>
                  {authState.user.displayName}
                  </Button>
                  <Menu id="profile-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeProfileMenu}>
                    <MenuItem onClick={this.organiserForm}>Organiser profile</MenuItem>
                    <Divider/>
                    <MenuItem onClick={this.logout}>Logout</MenuItem>
                  </Menu>
                </>
                )}
              </IfFirebaseAuthed>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              onClick={this.handleDrawerToggle}
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              ModalProps={{ onBackdropClick: this.handleDrawerToggle, onEscapeKeyDown: this.handleDrawerToggle }}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/about" component={About}  />
            <Route path="/organiser" component={OrganiserForm}  location={location}/>
            <Route path="/login" component={Login}  location={location}/>
            <Route path={urljoin(`${match.path}`)} component={Competitions}  location={location}/>
          </Switch>
        </main>
      </div>
    );
  }
  
  tileContent({ date, view }) {
    
    const datesToAddContentTo = [moment(), moment().add(1, 'days'), moment().add(3, 'days')];
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (datesToAddContentTo.find(dDate => dDate.isSame(date,"day"))) {
        return 'My content';
      }
    }
  }
  
  tileClassName({ date, view }) {
    
    const datesToAddContentTo = [moment(), moment().add(2, 'days'), moment().add(4, 'days')];
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (datesToAddContentTo.find(dDate => dDate.isSame(date, "day"))) {
        return 'event-day';
      }
    }
  }

}

export default compose<IProps, IProps>(
  withStyles(styles, { withTheme: true }),
  firestoreConnect( function(props:any):any {
    if(props.match && props.match.params.competitionId) {
      return [
        {
          collection: `competitions`,
          doc: props.match.params.competitionId,
 /*         subcollections:[
            {collection: "rounds"},
            {collection: "players"}
          ],*/
          storeAs:"competition",
        }
      ];
    } else {
      return [];
    }
  }),
  connect<any, IProps, IProps>(({ firestore: { ordered } }:any, props:IProps) => ({
    competition: ordered.competition?ICompetition.fromFirestore(ordered.competition[0]):undefined
  }))
)(App);