import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // import css

export default function confirmDialog (properties:any): void {

    let props: any = {
        title: "Alert"
    };
    props = Object.assign({}, props, properties);
    confirmAlert(props);
  }

  /*

              <Dialog
                open={this.state.confirmDelete}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Delete Round {roundId} ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.deleteRound} >
                    Yes
                  </Button>
                  <Button onClick={this.closeDialog} color="primary" autoFocus>
                    No
                  </Button>
                </DialogActions>
              </Dialog>
   */