import React, {Component, ReactNode} from "react";
import { compose} from "recompose";
import { firestoreConnect, isLoaded} from "react-redux-firebase";
import { connect } from "react-redux";
import { ICompetition } from "../../models/Competition";
import { Pairing, Pairings } from "../../models/Pairing";
import { Player } from "../../algorithms/managers/ICompetitionManager";

interface IState {
    roundId?: number;
    wins?: Map<string, number>|null;
  }
  
  interface IInputProps {
    roundId?: number;
    competitionId?: string;
  }
  
  interface IProps extends IComponentProps, IInputProps {
    competition: ICompetition;
    players:{[id:string]: Player};
    allPairings:Pairing[][];
    currentPairings:Pairing[];
    match:any;
  }

class StreamPairings extends React.Component<IProps, IState> {
  
    constructor(props: IProps) {
      super(props);

    }

    render(): ReactNode {
        const {competition, currentPairings, players, allPairings} = this.props;

        if(!isLoaded(competition, currentPairings, players, allPairings)) {
          return <></>;
        }
 
        let rows:ReactNode[] = [];
        Object.values(currentPairings).forEach((pair:Pairing)=>{
          if(pair.key) {
            let p1:Player|null = pair.player1? players[pair.player1]: null;
            let p2:Player|null = pair.player2? players[pair.player2]: null;
            rows.push(  <tr>
                <td className="number"><div className="cell-back"></div> {pair.key} </td>
                <td><div className="cell-back"></div>{p1&&p1.name}</td>
                <td><div className="cell-back"></div>{p1&&p1["club"]}</td>
                <td><div className="cell-back"></div>{pair.result1}</td>
                <td><div className="cell-back"></div>{pair.result2}</td>
                <td><div className="cell-back"></div>{p2&&p2.name}</td>
                <td><div className="cell-back"></div>{p2&&p2.club}</td>
              </tr>
            );
          }
        });

        return (<div className="stream-container">
          <div className="header">
            <h1>{this.props.competition.title}</h1>
            <h2>Round {allPairings.length}. Pairings</h2>
          </div>
          <table className="stream"  cellSpacing="0" cellPadding="0">
            <tr>
              <th><div className="cell-back"></div>Lane</th>
              <th><div className="cell-back"></div>Team</th>
              <th><div className="cell-back"></div>Club</th>
              <th><div className="cell-back"></div></th>
              <th><div className="cell-back"></div></th>
              <th><div className="cell-back"></div>Team</th>
              <th><div className="cell-back"></div>Club</th>
            </tr>
            {rows}
          </table>
          <div className="powered-by">allresults.live</div>
        </div>);
    }
}

export default compose<IProps, IInputProps>(
  firestoreConnect((props: IProps) =>
    [{ collection: `competitions`,
       doc: props.competitionId,
       subcollections:[{
            collection: "rounds"
        }],
        storeAs:"rounds"
      },
      {
        collection: `competitions`,
        doc: props.competitionId,
        subcollections:[{
          collection: "players"
        }],
        storeAs:"players",
      },
        { collection: `competitions`,
         doc: props.competitionId,
         storeAs: "competition"
        }
      ]),
  connect(({ firestore: { data, ordered } }: any, props:IProps) => {
    let roundPairings:Pairings|null = ordered.rounds?(props.roundId ? ordered.rounds[props.roundId]: ordered.rounds.slice(-1)[0]) : null;

    return {
      roundId: ordered.rounds?.length,
      allPairings:ordered.rounds,
      players: data.players,
      currentPairings: roundPairings,
      competition: ICompetition.fromFirestore( ordered.competition?.[0])
    };
  })
)(StreamPairings);