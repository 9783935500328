import firebase from "firebase/app";
import React, { ReactNode} from "react";
import {Route, Switch} from "react-router-dom";
import StreamPairings from "./stream-pairings";
import StreamScene from "./stream-scene";
import StreamStandings from "./stream-standings";
import { createFirestoreInstance } from "redux-firestore";
import store from "../../redux/store";
import "./index.css";

firebase.analytics();

const rrfProps: any = {
    firebase,
    config: {},
    dispatch: store.dispatch,
    createFirestoreInstance
 };

class Stream extends React.Component {
    render(): ReactNode {

        return (<>
                <Switch>
                    <Route path="/stream/:competition/pairings" render={(props) => <StreamPairings {...props} competitionId={props.match.params.competition}/>}  />
                    <Route path="/stream/:competition/standings" render={(props) => <StreamStandings {...props} competitionId={props.match.params.competition}/>}  />
                </Switch>
                </>
        );
    }
}

export default Stream;
