
import React, { ReactNode } from "react";
import { Columns } from "../../common/Columns"; 
import { TextField, IconButton, Divider  } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { compose, withHandlers  } from "recompose";
import { firestoreConnect} from "react-redux-firebase";
import { Player } from "../../models/Pairing";
import { updatePlayer, deletePlayer } from "../../actions";
import confirmDialog from "../../common/confirmDialog";
import { connect } from "react-redux";

interface IState {
  player:Player;
}

interface IInputProps {
  id: string|null;
  player: Player;
  competitionId: string;
  columns: string[];
  readOnly?: boolean;
  num: number|null;
  canDelete?: boolean;
}

interface IProps extends IComponentProps, IInputProps {
  dispatch:any;
}

class PlayerRow extends React.Component<IProps, IState> {
  constructor(props:IProps) {
    super(props);
    this.state = {
      player: props.player
    };
  }

  onDelete = async ()=> {
    const {id, competitionId, player,} = this.props;

    if(this.props.readOnly || !id) {
      return;
    }

    const options: any = {
      title: `Delete player?`,
      message: `Do you want to delete player ${player.name}'?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await this.props.dispatch(deletePlayer(competitionId, id));
            } catch (error) {
              console.error("Error deleting player: ", error);
            }
          }
        },
        {
          label: "No",
        }
      ]
    };

    confirmDialog(options);
  }

  componentWillReceiveProps(nextProps:IProps):void {
    this.setState({ player: nextProps.player });
  }

  async updatePlayer(id:string, player:Player): Promise<void>  {
    const {readOnly, competitionId} = this.props;
    if(readOnly) {
      return;
    }
    try {
      await this.props.dispatch(updatePlayer(competitionId, player));
    } catch (error) {
      console.error("Error updating player: ", error);
    }
  }

  onChange = (e:any) => {
    var player:Player = {...this.state.player};

    if(e.target.name === "seed") {
      player[e.target.name] = Number(e.target.value);
    } else {
      player[e.target.name] = e.target.value;
    }
    this.setState({player});
  }

  handleKeyPress =(e:any) => {
    if (e.key === 'Enter') {
      this.updatePlayer(this.props.id||"", this.state.player)
    }
  }

  render(): ReactNode {
    const {player} = this.state;
    const {readOnly, canDelete, columns} = this.props;

    if(!player) {
      return null;
    }
    let id = this.props.id;

    let cells:ReactNode[] = columns.map((c, i)=> {
      return (
        <div key={`${c}-${id}`}  className={"flex-row details" + (player[c]||!readOnly?"":" empty")}>
          
          <TextField fullWidth
            placeholder={readOnly?"":c}
            name={c}
            value={player[c]||""}
            type={c==="seed"?"number":"text"}
            onChange={this.onChange}
            onBlur={()=>this.updatePlayer(this.props.id||"", this.state.player)}
            InputProps={{
              disableUnderline:readOnly,
              readOnly: readOnly
              }}> </TextField >
        </div>);
    });

    let num:number|null = this.props.num;

    return (
      <>
        <Divider className="flex-row separator"></Divider>
        {!readOnly && canDelete && 
          <div key={`static-delete-${id}`} className="flex-row button">
            <IconButton  onClick={this.onDelete} ><DeleteIcon /></IconButton>
          </div>
        }
        <div key={`static-num-${id}`}  className="flex-row num">
           {num===null? "" : (num+1)}
        </div>
        <div key={`static-name-${id}`} className="flex-row first">
          <TextField  name="name" value={player.name} fullWidth  onChange={this.onChange} 
              onBlur={()=>this.updatePlayer(this.props.id||"", this.state.player)}
              onKeyPress={(e)=>this.handleKeyPress(e)}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
                }}> </TextField >
        </div>
        {cells}
        </>
    );
  }

}


export default compose<IProps, IInputProps>(
  firestoreConnect((props) =>[]),
  connect(({ firestore: { data, ordered } }:any, props) => ({})) // for the dispatch()
)(PlayerRow);