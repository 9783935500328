import "regenerator-runtime/runtime";
import React, {Component, ReactNode, version} from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider  } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { Route, Switch, Redirect } from "react-router-dom";
import "./index.css";
import App from "./App";
import Dashboard from "./components/competitions/Dashboard"
import store from "./redux/store";
import HistoryRouter from "./redux/HistoryRouter";
import firebaseConfig from "./firebaseConfig";
import firebase from "firebase/app";
import {
  FirebaseAuthProvider,
/*  IfFirebaseAuthed,
  IfFirebaseAuthedAnd*/
} from "@react-firebase/auth";
import {CssBaseline, Snackbar, Button} from "@material-ui/core";

import { MuiThemeProvider, createMuiTheme, Theme } from "@material-ui/core/styles";

import * as Sentry from '@sentry/react';
import {version as app_version} from "./common/utils";
import config from "./config";
import { FlagsProvider } from "react-feature-flags";
import Stream from "./components/stream"; 


app_version().then((v)=>{
  let sentry_config = config.sentry;
  sentry_config.release = v.replace("/", "@");
  Sentry.init(sentry_config);
});

const theme:Theme = createMuiTheme({
  palette: {
    /*primary: {
      main: green[500],
    },*/
  },
  props: {
    MuiTable: {
      size: "small"
    },
  },
  overrides: {
    MuiTable: {
    },
    MuiTableCell: {
      sizeSmall:{
        padding:"6px"
      }
    }
  }
});


console.log(process.env.NODE_ENV);
firebase.analytics();
firebase.analytics().logEvent('notification_received');


const rrfProps: any = {
   firebase,
   config: {
    userProfile: "users",
    useFirestoreForProfile: true,
   },
   dispatch: store.dispatch,
   createFirestoreInstance
};


// setup react-redux so that connect HOC can be used
class Main  extends Component {
  constructor(props:Readonly<{}>) {
    super(props);
  /*  serviceWorker.register({
      onUpdate:()=> {
        console.log("on update");
        this.setState({ open: true});
      }
    }); */
    if(process.env.NODE_ENV !== "development") {
      updateAvailable().then((update:boolean)=> {
        if(update) {
          this.setState({ open: true});
        }
      });
    }
  }

  state = {
    open: false,
  };

  componentDidUpdate(prevProps:Readonly<{}>):void {
    // typical usage (don't forget to compare props):
    console.log("Main componentDidUpdate");
  }

  onButtonClick = () => {
    if(window.registration) {
      window.registration.update();        
    }
    window.location.reload(true);
  }

  handleClose = () => {
    // this.setState({ open: false});
   // window.location.reload(true);
  }

  render(): ReactNode {
    const {open} = this.state;
    return (
      <FlagsProvider value={store.getState().features}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>

            <CssBaseline />
            <HistoryRouter>
                  <Switch>
                    <Route path="/about" component={App} />
                    <Route path="/login" component={App} />
                    <Route path="/competitions/:competitionId/dashboard" component={Dashboard} />
                    <Route path="/competitions" component={App} />
					          <Route path="/organiser" component={App} />
                    <Route path="/stream" component={Stream} />
                    <Route
                        path="/c/:id"
                        render={props => {
                          console.log(props);
                          return (
                          <Redirect to={`/competitions/${props.match.params.id}`} />
                        );}}
                      />
                    <Redirect path="/" to="/competitions" />
                  </Switch>
              </HistoryRouter>
          </ReactReduxFirebaseProvider>
        </Provider>

        <Snackbar
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          open={open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">New version available</span>}
          action={(
              <Button color="primary" size="small" onClick={this.onButtonClick}>
                Reload
              </Button>
            )}
        />
      </FirebaseAuthProvider>
      </FlagsProvider>
      );
    }
  }


ReactDOM.render(
	<MuiThemeProvider theme = { theme }>
	  <Main/>
	</MuiThemeProvider>
  ,document.getElementById("root"));

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA

// @ts-ignore:
import currentVersionFile from "./static/version.txt";

async function readFile(fileName:string):Promise<string> {
  console.log(fileName);
  let result:any = await fetch(fileName);
  let text:string = await result.text();
  return text;
}

async function updateAvailable():Promise<boolean> {
  let liveVersionFile:string = "/version.txt";
  let liveVersion:string = await readFile(liveVersionFile);
  console.log("r:" + liveVersion);
  let currentVersion:string = await readFile(currentVersionFile);
  console.log("v:" + currentVersion);
  return liveVersion !== currentVersion;
}

