// @ts-ignore:
import currentVersionFile from "../static/version.txt";
import urljoin from "url-join";
const normalizeUrl = require("normalize-url")

export function join_paths(...parts: string[]): string {
    var path = urljoin(parts);
    var url = new URL(normalizeUrl('http://localhoist' + path));
    return url.pathname
}

async function readFile(fileName:string):Promise<string> {
    console.log(fileName);
    let result:any = await fetch(fileName);
    let text:string = await result.text();
    return text;
  }


let version_number:string;
export async function version(): Promise<string> {
    version_number = version_number || await readFile(currentVersionFile);
    return version_number;
}