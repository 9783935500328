import React, {Component, ReactNode} from "react";

import {Button, List, ListItem, ListItemIcon, ListItemText}  from "@material-ui/core";

import {Done as DoneIcon, People as PeopleIcon, ArrowRightAlt as ArrowRightIcon, Info as InfoIcon} from "@material-ui/icons";
import {connect} from "react-redux";
import { compose } from "recompose";
import { history } from "../../redux/HistoryRouter";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import firebase from "firebase/app";
import { firestore } from "firebase";
import { ICompetition } from "../../models/Competition";
import { Pairing } from "../../models/Pairing";
import ICompetitionManager, { Player } from "../../algorithms/managers/ICompetitionManager";
import { Managers } from "../../algorithms/managers/Managers";
import { join_paths } from "../../common/utils";
import confirmDialog from "../../common/confirmDialog";

interface IState {
    manager?: ICompetitionManager;
}

interface IProps extends IComponentProps {
  competition: ICompetition;
  rounds:Pairing[][];
  players:{[id:string]: Player};
}

class CompetitionMenu extends Component<IProps, IState> {

  constructor(props:IProps) {
    super(props);
    console.log("constructor", this.props?.competition, this);

    this.state = {
      manager: this.getManager()
    };
  }

  assignLine(pairing: Pairing[]) {
    //TODO: throw new Error("Method not implemented.");
  }

  getManager() : ICompetitionManager|undefined {
    const { rounds, competition, players} = this.props;
    if(isLoaded(rounds, competition, players)) {      
      return this.state?.manager || competition.getManager(rounds, players);
    }
  }

  componentDidUpdate(prevProps:Readonly<{}>):void {
    let manager = this.getManager();
    if(this.state.manager != manager) {
      this.setState({
        manager:manager
      });
    }
  }

  canEdit():boolean {
    let user:firebase.User|null = firebase.auth().currentUser;
    if(!user) {
      return false;
    }
    return this.props.competition && this.props.competition.owner === user.uid || false;
  }

  onNextRoundClick = () => {
    const {rounds} = this.props;
    let missingResults = false;
    if(rounds && rounds.length > 0) {
      let pairings:Pairing[] = rounds[rounds.length - 1];
      for( let pair of Object.values(pairings)) {
        if(pair.player1 && !pair.result1 || pair.player2 && !pair.result2) {
          missingResults = true;
          break;
        }
      }
    } 

    if(missingResults) {
      const options: any = {
        title: "Missing results!",
        message: `Some results are missings. Create next round anyway?`,
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                this.createRound();
              } catch (error) {
                alert(`Error creating round`);
                console.log(error);
              }
            }
          },
          {
            label: "No",
          }
        ]
      };
      confirmDialog(options);
    } else {
      this.createRound();
    }
  }

  showPlayers = () => {
    var target:string = join_paths( this.props.match.url, `players`);
    history.push(target);
  }

  changeRound = (round:number) => {
    var target:string = join_paths( this.props.match.url, `rounds/${round}`);
    history.push(target);
  }

  createRound = () => {

    const props:IProps = this.props;
    const round:number = props.rounds?Object.keys(props.rounds).length + 1 : 1;
    const user:firebase.User|null = firebase.auth().currentUser;
    
    const {manager} = this.state;
    const {players} = props;

    if(!manager) return;

    const now:firestore.Timestamp = firestore.Timestamp.now();
    let docs:any  = {
      created: now,
      modified: now,
      owner:user&&user.uid
    };

    let rounds:Pairing[][] = this.props.rounds;
    console.log(manager);
    
    let pairing:Pairing[] = manager.createPairings(props.competition, rounds, players, round);
    this.assignLine(pairing);


    pairing.forEach((p, i) => {
      docs[`${i+1}`] = {
        key:`${i+1}`,
        player1: p.player1 || null,
        player2: p.player2 || null,
        result1: p.result1 || null,
        result2: p.result2 || null,
        spot: p.spot
      };
    });

    console.log(docs);

    props.firestore.collection(`competitions/${props.match.params.competitionId}/rounds/`).doc(`${round}`)
            .set(docs, {merge:true})
            .then(()=> {
              let target:string = join_paths( props.match.url, `/rounds/${round}/pairings`);
              history.push(target);
            });
  }


  render():ReactNode {
    const { rounds, match, competition, players} = this.props;
    const manager = this.state.manager;
    var roundList:ReactNode = null;

    if(!(isLoaded(rounds, competition, players) && manager)) {
      return null;
    }
    const moreRounds:boolean = rounds && (rounds.length < manager.getRoundCount()) || false;
    
    if(rounds) {
      roundList = rounds.map((r, index) => (
        <ListItem button key={index} onClick={()=>this.changeRound(index+1)}>
            <ListItemIcon>{index<rounds.length-1?<DoneIcon />:<ArrowRightIcon/>}</ListItemIcon>
            <ListItemText primary={`Round ${index+1}`} />
        </ListItem>));
      } else {
      }

        return(
            <List>
              <ListItem button key="details" onClick={()=>history.push(join_paths( match.url, `details`))}>
                  <ListItemIcon><InfoIcon /></ListItemIcon>
                  <ListItemText primary="Details" />
              </ListItem>
              <ListItem button key="players" onClick={this.showPlayers}>
                  <ListItemIcon><PeopleIcon /></ListItemIcon>
                  <ListItemText primary="Players" />
              </ListItem>
                { roundList}

                {this.canEdit() &&
              <ListItem>
                <Button fullWidth variant="contained" color="primary" onClick={this.onNextRoundClick} disabled={!moreRounds}>
                  {rounds.length > 0? "Next round": "Start competition"}
                </Button>
              </ListItem>
                }
            </List>
        );
    }

}

export default compose<IProps, IProps>(

  firestoreConnect((props:IProps) =>  [
    { collection: `competitions`,
      doc: props.match.params.competitionId,
      storeAs: "competition"
    },
    {
      collection: `competitions`,
      doc: props.match.params.competitionId,
      subcollections:[{
        collection: "players"
      }],
      storeAs:"players",
    },
    {
      collection: `competitions`,
      doc: props.match.params.competitionId,
      subcollections:[{
        collection: "rounds"
      }],
      storeAs:"rounds",
    },
    ]),

  connect(({ firestore: { data, ordered } }:any, props:IProps) => {
    return {
      players: data.players,
      competition: ordered.competition&&ICompetition.fromFirestore( ordered.competition[0]),
      rounds: ordered.rounds,
    };
  })
)(CompetitionMenu);