import React, { ReactNode } from "react";
import {Select, ListItemText, Hidden, Grid, NativeSelect, Box, OutlinedInput} from "@material-ui/core";

import { compose, withHandlers  } from "recompose";
import { firestoreConnect} from "react-redux-firebase";
import firebase, { firestore } from "firebase/app";
import { Pairing, Player } from "../../models/Pairing";
import {connect} from "react-redux";

interface IState {
  pairing:Pairing;
}

interface IInputProps {
  pairing:Pairing;
  spot:string;
  roundId: string;
  competitionId: string;
  players:{[id:string]: any};
  wins?: Map<string, number>|null;
  id: string;
  readOnly: boolean;
}

interface IProps extends IComponentProps, IInputProps {
}

class PairingRow extends React.Component<IProps, IState> {
  constructor(props:IProps) {
    super(props);
    this.state = {
      pairing: props.pairing
    };
  }

  onChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
    let pairing:Pairing = {...this.state.pairing};
    pairing[e.target.name] = e.target.value;
    this.setState({pairing});

    this.saveResult(pairing);
  }

  saveResult=(pairing:Pairing) => {
    const {firestore, roundId} = this.props;
    console.log("change result");
    let user:firebase.User|null = firebase.auth().currentUser;
    if(!user) {
      return;
    }

    let data:Pairing = new Pairing({owner: user.uid});
    data[pairing.key] = {
      result1: pairing.result1 || "",
      result2: pairing.result2 || "",
      player1: pairing.player1 || "",
      player2: pairing.player2 || ""
    };

    const ref:firestore.CollectionReference  = firestore.collection(`competitions/${this.props.competitionId}/rounds/`);
      ref.doc(roundId).set(Object.assign({}, data), {merge:true})
      .catch((error:Error) => {
        console.error("Error updating document: ", error);
      });
  }

  playerNode(targetName:string, playerId:string, name:string, wins:number, result:string|undefined, 
    options:ReactNode,  playerList:ReactNode, invert:boolean= false): ReactNode {

    return (
      <Grid item container xs={12} md style={this.styles.playerLine} alignItems="center" >
        <Grid item  xs={12}>
          <Box display="flex" width="100%" alignItems="center">
            <Box display="flex" flexGrow={1} order={{ sm: 0, md: invert?2:0 } } >
            {(this.props.readOnly || true) &&
              <ListItemText primary ={playerId ? name:"bye"}  style={this.styles.playerName}> </ListItemText>
              ||
              <NativeSelect
                name={targetName=="result1"?"player1":"player2"}
                value={playerId || ""}
                onChange={this.onChange}
              >
              {playerList}
            </NativeSelect>
            }
            </Box>
            <Box display="flex" style={this.styles.wins} order={{ sm: 1, md: 1 }}>
              <ListItemText primary ={wins}></ListItemText>
            </Box>
            <Box display="flex" order={{ sm: 2, md: invert?0:2 }} >
              <Box>
              {playerId && (
                  (!this.props.readOnly &&
                  <NativeSelect
                    name={targetName}
                    input={
                      <OutlinedInput labelWidth={0} inputProps={{style:this.styles.pointsInput }}/>
                    }
                    value={result || ""}
                    onChange={this.onChange}
                  >
                    {options}
                  </NativeSelect>)
                  || <OutlinedInput labelWidth={0} readOnly={true} disabled={true}
                                inputProps={{style:this.styles.pointsInput }}
                                value={result || ""}></OutlinedInput>
                  )

              }
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  readonly styles:any = {
    placeNumber:{
      padding:"4px",
      backgroundColor:"gray",
      color:"white"
    },
    wins: {
      width:"24px",
      textAlign: "center"
    },
    playerLine:{
      padding:"4px"
    },
    playerName:{
      margin: "4px"
    },
    points:{

    },
    pointsInput:{
      padding:"8px",
      width: "32px",
      color: "black",
      textAlign: "center"
    },
  };

  render():ReactNode {
    const {players, wins} = this.props;
    const {pairing} = this.state;

    const player1 = pairing.player1 ? (players[pairing.player1]||{name:"(unknown player)"}): null;
    const player2 = pairing.player2 ? (players[pairing.player2]||{name:"(unknown player)"}): null;
    
    const bye = !(player1 && player2);

    let options:ReactNode[] = [<option key="-" value="" />];
    for( let i:number = 0; i <= 13; i++) {
      options.push( <option key={i} value={i}>{i}</option>);
    }

    let playerList:ReactNode[] = [<option key="-" value="" />];
    Object.keys(players).forEach(function (id:string, index:number) {
      playerList.push( <option key={id} value={id}>{players[id]?.name}</option>);
    });


    return (
        <Grid item container >
            <Grid item container alignItems="center" md={1} xs={12} style={this.styles.placeNumber}>
              <Grid item >{bye? "bye": `Line ${this.props.spot}`}</Grid>
            </Grid>
            { pairing.player1 &&
                 this.playerNode("result1", pairing.player1, player1.name, 
                 wins && wins.get(pairing.player1||"")||0, pairing.result1, options, playerList)
            } 
            {!bye &&
              <Hidden smDown>
                <Grid item >
                  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    <Box alignContent="center">:</Box>
                  </Box>
                </Grid>
              </Hidden>
            }

            { pairing.player2 && 
                 this.playerNode("result2", pairing.player2, player2.name, 
                        wins && wins.get(pairing.player2||"")||0, pairing.result2, options, playerList, !bye)
            }

            { bye &&
              <Grid container item style={this.styles.playerLine} xs={12} md  alignItems="center">
              </Grid>
            }

          </Grid>

        );
  }
}

export default compose<IProps, IInputProps>(
  firestoreConnect((props:IProps) =>  []),
)(PairingRow);