import firebase from "firebase/app";

import ICompetitionManager from "../algorithms/managers/ICompetitionManager";
import { Managers } from "../algorithms/managers/Managers";
import { Player } from "../algorithms/managers/ICompetitionManager";
import {Pairing} from "./Pairing";
import { GeoPoint } from "@google-cloud/firestore";
import { DocumentReference, DocumentSnapshot } from "@firebase/firestore-types";


export class ICompetition {

    constructor( competition?: ICompetition) {
        if(competition) {
            Object.assign(this, competition);
        }
     }

    [key:string]: any;

    id?:string;
    title?:string;
    date?:Date;
    rounds?:number;
    firstLine:number = 1;
    randomLine: boolean = true;
    logo?:string;
    organiser?:DocumentReference;

    director?:string;
    chiefArbiter?:string;
    arbiters?:string;
    location?:string;
    geopoint?:GeoPoint;
    place_id?:string;
    owner:string|null=null;
    rankings: string[]|null = null;
    columns:string[] = [];
    pairingSystem:string = "";

    created?:Date;
    modified?:Date;

    getManager( rounds:Pairing[][], players:{[id:string]: Player}):ICompetitionManager {
        let managerName:string = this.pairingSystem || "SwissSystem";
        return new Managers[managerName](this, rounds, players);
    }

    static fromFirestore(data:any):ICompetition {

        let competition:ICompetition;

        if(data) {
            competition = {...data} as ICompetition;
            if(data.date instanceof firebase.firestore.Timestamp) {
                competition.date = data.date.toDate();
            }
        } else {
            competition = new ICompetition();
        }

        competition.firstLine = competition.firstLine || 1;
        return new ICompetition(competition);

    }
}