import React, {Component, ReactNode} from "react";
import {Typography} from "@material-ui/core";
import {version} from "./common/utils";


interface IState {
    version?: string;
  }
  interface IProps extends IComponentProps {
  }

class About extends Component<IProps, IState> {

    constructor(props:IProps) {
        super(props);
        this.state = {
        };

        version()
            .then((version:string)=> {
                this.setState({version});
            });
      }

    render():ReactNode {
        const {version} = this.state;
        return (
            <>
            <div><h1>About</h1>
                <p>Tool for organizing competitions.</p>
                <p>Works in any operating system with modern browser.</p>
                <p>Supports offline mode.</p>
                <p></p>
                <p><a href="mailto:info@allresults.live" target="_top">info@allresults.live</a></p>
            </div>
            <Typography variant="caption" display="block" gutterBottom>
                Version: {version}
            </Typography>
            </>
        );
    }
}

export default About;