import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Organiser } from "../models/Organiser";
import {ICompetition} from "../models/Competition";
import { Pairing, Pairings } from "../models/Pairing";
import { firestore } from "firebase";
import moment from "moment";
import { TDocumentDefinitions } from "pdfmake/interfaces";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class PdfGenerator {

  async createAll(competition:ICompetition, docs:[string, any[]][]): Promise<pdfMake.TCreatedPdf> {
    let content: object[] = [];

    for( const d of  docs) {
      let header: object[] = await this.createHeader(d[0], competition);
      content = content.concat(header);
      content = content.concat(d[1]);
      content.push({text:"", pageBreak:"after"});
    }
    return await this.generate("all data", competition, content);
  }

  async create(tableTitle: string, competition:ICompetition, body:object[], landscape:boolean=false): Promise<pdfMake.TCreatedPdf> {
    let header: object[] = await this.createHeader(tableTitle, competition);
    let content:object[] = header.concat(body);
    return await this.generate(tableTitle, competition, content, landscape);
  }

  async generate(tableTitle: string, competition:ICompetition, content:object[], landscape:boolean=false): Promise<pdfMake.TCreatedPdf> {

      var dd:TDocumentDefinitions = {
          info: {
            title: competition.title + " - " + tableTitle,
            author: "All Results",
            subject: competition.title + " - " + tableTitle,
            keywords: "result pairing petanque chess",
          },
          pageOrientation: landscape?"landscape":"portrait",
          content: content,
          footer:(currentPage: number, pageCount: number)=> {
            return {
              margin: [30, 0],
              columns:  [
                competition.title,
                {text:"https://allresults.live", alignment:"right"}
              ]
            };
          },
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10]
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5]
            },
            footnote: {
              fontSize: 9,
            },
            mainTable: {
              margin: [0, 5, 0, 15]
            },
            tableHeader: {
              bold:true,
              fillColor: "#DDDDDD"
            },
            dark: {
              fillColor: "#F7F7F7"
            },
            light: {
            },
            unused: {
              fillColor: "#6E6E6E"
            },
            topInnerCell: {
              margin: [0, 0, 0, 0]
            },
            cell: {
              alignment: "center",
            }
          }
      };

        return pdfMake.createPdf(dd);
    }

    async createHeader(tableTitle: string, competition:ICompetition): Promise<object[]> {

      let logoImage: string|undefined;
      let organiser: Organiser|undefined;
      if(competition.organiser) {
        let snapshot: firestore.DocumentSnapshot = await competition.organiser.get();
        organiser = snapshot.data() as Organiser;
      }

      if(organiser && organiser.logoImage) {
        logoImage = await this.getImage(organiser.logoImage);
      }

      let headerLeft: object[] = [];
      let headerRight: object[] = [];

      if(organiser && organiser.title) {
        headerLeft.push(this.keyValue("Organiser", organiser.title));
      }
      this.addKeyValue(headerLeft, "Tournament Director: ", competition.director);
      this.addKeyValue(headerLeft, "Chief Arbiter: ", competition.chiefArbiter);
      this.addKeyValue(headerLeft, "Arbiter(s)", competition.arbiters);
      if(competition.date) {
        let date:string = moment(competition.date).format("YYYY MM DD");
        this.addKeyValue(headerLeft, "Date", `${date}`);
      }
      this.addKeyValue(headerLeft, "Location", competition.location);

      headerLeft.push(this.keyValue("Direct link", `https://allresults.live/c/${competition.id}`));

      if(logoImage) {
        headerRight.push({
          image: logoImage,
          width: 100
        });
      }

      let header: object[] = [
          { text: `${competition.title}`, style: "header", alignment:"center"},
          {
            table:{
              widths: ["*", "auto"],
              body:[[headerLeft, headerRight]]
            },
            layout: "noBorders"
          },
          {text: tableTitle, style: "subheader"}
        ];
      return header;
    }

    addKeyValue(text:object[], key:string, value:string|undefined):void {
      if(value) {
        text.push(this.keyValue(key, value));
      }
    }

    keyValue(key:string, value:string):object {
      return {
        text: [
          {text:`${key}: `, bold: true},
          {text:value}
        ]
      };
    }

  async getImage(url:string):Promise<string> {
    return new Promise(async (resolve, reject) => {
      const response:Response = await fetch(url);
      var reader:FileReader = new FileReader();
      reader.onload = (ev:ProgressEvent) => {
          resolve(reader.result as string);
      };
      let blob:Blob = await response.blob();
      reader.readAsDataURL(blob);
    });
  }



}