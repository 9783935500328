const firebaseConfig:any = process.env.NODE_ENV === "production"?
{
    apiKey: "AIzaSyBPxEjwa5bIAS5Fra-5GC1rDTGI4GkCo1M",
    authDomain: "allresults-00.firebaseapp.com",
    databaseURL: "https://allresults-00.firebaseio.com",
    projectId: "allresults-00",
    storageBucket: "allresults-00.appspot.com",
    messagingSenderId: "697901261610",
    appId: "1:697901261610:web:ce94db165e53710be77453",
    measurementId:"G-PN4TR3KW2G"
}
:
{
    apiKey: "AIzaSyA5JKicMUQb1_lPv50dUR2nd8l8Yb9sqZw",
    authDomain: "allresults-dev.firebaseapp.com",
    databaseURL: "https://allresults-dev.firebaseio.com",
    projectId: "allresults-dev",
    storageBucket: "allresults-dev.appspot.com",
    messagingSenderId: "6405058111",
    appId: "1:6405058111:web:4269bb8886447439",
    measurementId:"G-PN4TR3KW2G"
};

export default firebaseConfig;