import urljoin from "url-join";

import React, { Component, ReactNode } from "react";
import { Route, Switch, Redirect} from "react-router-dom";
import { Typography, Grid, Avatar, FormControl, Hidden, Select, MenuItem, Box, Divider} from "@material-ui/core";
import { history } from "../../redux/HistoryRouter";

import Players from "./Players";
import Rounds from "./Rounds";
import Create from "./Create";

import { connect } from "react-redux";
import { compose } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { Organiser } from "../../models/Organiser";
import ImportPlayers from "./ImportPlayers";
import { ICompetition } from "../../models/Competition";
import { DocumentReference, DocumentSnapshot } from "@firebase/firestore-types";
import {IFeature, setFeature} from "../../reducers/features";
import {Registration} from "../registration/Registration";

interface IState {
  logoImage?: string;
  page?:string;
}

interface IProps extends IComponentProps {
  organiser: Organiser;
  competition: ICompetition;
  menuItems:{[id:string]: string};
  features:IFeature[];
}


class Show extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      page: ""
    };
  }

  async componentDidUpdate(prevProps:IProps): Promise<void> {
    const {competition} = this.props;

    if(isLoaded(competition) &&
      this.props !== prevProps) {

      const features = this.props.features;

      //setFeature("adminOnly", competition.adminOnly);
      

           // if(this.props.organiser !== prevProps.organiser )
      {
        let ref:DocumentReference|undefined = this.props.competition.organiser;
        if(ref) {
          let snapshot:DocumentSnapshot = await ref.get();
          let organiser:Organiser|undefined = snapshot.data() as Organiser;

          if(this._isMounted) {
            this.setState({
              logoImage:organiser && organiser.logoImage
            });
          }
        }
      }
      
      if(this._isMounted) {
        this.setState({
          page: this.selectedMenu(this.props.menuItems)
        });
      }
    }
  }

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  selectedMenu(menuItems:{[id:string]: string}):string {
    const { match} = this.props;

    for (const item of Object.entries(menuItems) || {}) {
      if(history.location.pathname.startsWith(urljoin(match.url, item[0]))) {
        return item[0];
      }
    }

    return "";
  }

  delete(id:string):void {
    /*firebase.firestore().collection("competitions").doc(id).delete().then(() => {
      console.log("Document successfully deleted!");
      this.props.history.goBack();
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });*/
  }

  onChange: React.ChangeEventHandler<{ name?: string; value: unknown} > =
    (e: React.ChangeEvent<{ name?: string; value: string }>) => {
    const { match} = this.props;
    history.push(urljoin( match.url, e.target.value));
  }

  render():ReactNode {
    const { match, competition, menuItems, organiser} = this.props;
    const { page, logoImage} = this.state;

    if(!isLoaded(competition, menuItems)) {
      return (<>Loading...</>);
    }

    let menu:ReactNode = Object.entries(menuItems).map((pair, index) => {
      return (<MenuItem key={index} value={pair[0]}>{pair[1]}</MenuItem>);
    });

    return (
      <>
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar
                style={{width:60, height:60, borderRadius:0, backgroundColor:"transparent" }}
                imgProps={{style: {objectFit:"contain"} }}
                src={logoImage}
                title="Logo"
              />
            </Grid>
            <Grid item xs>
              <Typography variant="h5">
                {competition.title}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Hidden smUp>
          <FormControl fullWidth>
            <Typography variant="overline" gutterBottom>
              <Select
                fullWidth
                value={page}
                onChange={this.onChange}
                inputProps={{
                  name: "page",
                  id: "page",
                }}
              >
                {menu}
              </Select>
            </Typography>
          </FormControl>
        </Hidden>
        <div>
          <Switch>
            <Route path={urljoin(match.path,`details`)} component={Create} />
            <Route path={urljoin(match.path,`players`)} component={Players} />
            <Route path={urljoin(match.path,`registration`)} component={Registration} />
            <Route path={urljoin(match.path,`import/players`)} component={ImportPlayers} />
            <Route path={urljoin(match.path,`rounds/:roundId`)} component={Rounds} />
            <Redirect path={match.path} to={urljoin(`${match.path}`, "details")} />
          </Switch>
        </div>
      </>
    );
  }
}

export default compose<IProps, IProps>(

  firestoreConnect((props:IProps) => {
    return  [ { collection: `competitions`,
                doc: props.match.params.competitionId,
                  subcollections:[{
                    collection: "rounds"
                }],
                storeAs:"rounds",
              }
          ];
      }
  ),
  connect(({firestore: {data, ordered}, features}:any, props) => {

    const { rounds} = data;

    let menuItems:{[id:string]: string} = {
      "details":"Details",
      "players":"Players"
    };
    for( let r :number = 1; rounds && rounds[r]; r++) {
      menuItems[`rounds/${r}`] = `Round ${r}`;
    }
    return {
          competition: ordered.competition && ordered.competition[0],
          menuItems: menuItems, 
          features
        };
      })
)(Show);
