import BaseRenderer from "./BaseRenderer";
import { ICompetition } from "../models/Competition";
import { Player } from "../algorithms/managers/ICompetitionManager";
import { Pairing } from "../models/Pairing";
import { HtmlPlayoffsTableCellRenderer, PdfPlayoffsTableCellRenderer } from "./PlayoffsCellsRenderer";
import { IRaport, Raports } from "../common/Raports";

export default class PlayoffsRenderer extends BaseRenderer {

    placeNo(index:number, round:number, playerCount:number):string {
        let rounds = playerCount > 0? Math.ceil( Math.log(playerCount) / Math.log(2)): 0;
        let groupPlayers = Math.ceil(Math.pow(2, rounds - round ));

        let group = Math.floor(index / groupPlayers);

        let start = group * groupPlayers;
        let end = start + groupPlayers - 1;
        end = Math.min(end, playerCount-1);

        if(start != end) {
            return `${start + 1}-${end + 1}`;
        } else {
            return `${start + 1}`;
        }
    }

    rowStyle(index:number, round:number, playerCount:number):string {
        let rounds = playerCount > 0? Math.ceil( Math.log(playerCount) / Math.log(2)): 0;
        let groupPlayers = Math.ceil(Math.pow(2, rounds - round ));

        let group = Math.floor(index / groupPlayers);

        return group % 2 ? "light" :"dark";
    }

    
    createTableHtml(): React.ReactNode {
        return (new HtmlPlayoffsTableCellRenderer()).render(this.players, this.allPairings, this.rounds, this.roundId);
    }

    createTablePdf(): any[] {
        return (new PdfPlayoffsTableCellRenderer()).render(this.players, this.allPairings, this.rounds, this.roundId);
    }

}
