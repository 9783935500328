import React, { ErrorInfo, ReactNode } from "react";
import * as Sentry from '@sentry/react';

interface IState {
  error: Error|null;
  errorInfo: ErrorInfo|null;
}

interface IProps {
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch?(error: Error, errorInfo: ErrorInfo): void {
      // Catch errors in any components below and re-render with error message
      var newState = {
        error: error,
        errorInfo: errorInfo
      };

      this.setState(newState);

      let self = this;
      Sentry.withScope(function(scope) {
        let c: any = self.props.children;
        scope.setContext("component props", c?.props);
        scope.setContext("component state", newState);
        Sentry.captureException(newState.errorInfo);
      });
    }

    render(): ReactNode {
      if (this.state.errorInfo) {
        return (
          <div>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        );
      }

      return this.props.children;
    }  
  }