import React, { Component, ReactNode } from "react";
import {TextField, FormGroup, Grid, Paper, Typography, Switch, FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Box, IconButton, Divider, Hidden, Checkbox, Dialog } from "@material-ui/core";
import {connect} from "react-redux";
import { compose, withHandlers  } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { history,  } from "../../redux/HistoryRouter";
import firebase from "firebase/app";
import { firestore } from "firebase";
import moment from "moment";
import { ICompetition } from "../../models/Competition";
import { Organiser } from "../../models/Organiser";
import { Redirect } from "react-router-dom";
import ICompetitionManager, { Player } from "../../algorithms/managers/ICompetitionManager";
import { Columns } from "../../common/Columns";
import { Managers } from "../../algorithms/managers/Managers";
import {CloudDownload as DownloadIcon, Print as PrintIcon, MoreVert as MoreVertIcon, Map as MapIcon} from "@material-ui/icons";
import { IRenderer } from "../../render/BaseRenderer";
import PdfGenerator from "../../common/PdfGenerator";
import { Pairing } from "../../models/Pairing";
import { Flags } from "react-feature-flags";
import LocationInput from "../../common/LocationInput";
import { GeoPoint } from "@google-cloud/firestore";


interface IState {
  update: boolean;
  competition: ICompetition|null;
  files?:any;
  errors?: any;
  canContinue?: boolean;
  renderer?:IRenderer;
  isAdmin: boolean;
  user:firebase.User|null;
}
interface IProps extends IComponentProps {
  competition?: ICompetition;
  players: {[id: string]: Player};
  rounds: Pairing[][];
  organiser?: Organiser;
  redirect?: string;
  auth : any;
}

class Create extends Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      update:Boolean(props.match.params.competitionId),
      competition: props.competition || new ICompetition(),
      files:{},
      errors: {},
      canContinue: false,
      isAdmin: false,
      user:null
    };
    
    console.log(this.state.competition);
  }
  
  isNew(): boolean {
    var user:firebase.User|null = firebase.auth().currentUser;
    return this.state.update == false && user != null;
  }
  

  canEdit(): boolean {
    let user = this.state.user;
    // @ts-ignore:
    return this.props.competition && (this.props.competition.owner === (user && user.uid) || this.state.isAdmin);
  }
  
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> =
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const state:IState = this.state;
    
    if(!state.competition) {
      return;
    }
    
    if( e.target.name.startsWith("column.")) {
      let target: HTMLInputElement = e.target as HTMLInputElement;
      let columnName:string = target.name.split(".")[1];
      //state.columns[columnName] = target.checked;
      state.competition.columns = state.competition.columns.filter(c => c !== columnName);
      if(target.checked){
        state.competition.columns.push(columnName);
      }
      
    } else if(e.target.type === "file") {
      let target: HTMLInputElement = e.target as HTMLInputElement;
      if(target && target.files) {
        state.files[e.target.name] = target.files[0];
      }
    } else if(e.target.type === "checkbox") {
      let target: HTMLInputElement = e.target as HTMLInputElement;
      state.competition[e.target.name] = Boolean(target.checked);
    } else if(e.target.type === "date") {
      if(Date.parse(e.target.value)) {
        state.errors.date = false;
        state.competition!.date = new Date(e.target.value);
      } else {
        state.competition!.date = undefined;
        state.errors.date = true;
      }
      
    } else {
      console.log(typeof state.competition[e.target.name]);
      if(typeof state.competition[e.target.name] === "number") {
        state.competition[e.target.name] = Number(e.target.value);
      } else {
        state.competition[e.target.name] = e.target.value;
      }
      state.errors[e.target.name] = !Boolean(e.target.value);
    }
    
    state.canContinue = !Object.values(state.errors).some(e=>e);
    
    this.setState(state);
  }

  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLDivElement> =
  (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLDivElement>) => {
    const state:IState = this.state;
    
    if((e.target as HTMLInputElement).required) {
      // @ts-ignore
      state.errors[e.target.name] = !Boolean(e.target.value);
    }
    
    state.canContinue = !Object.values(state.errors).some(e=>e);
    
    this.setState(state);
  }
  
  onSubmitButton: React.MouseEventHandler<HTMLButtonElement> =
  (e: React.MouseEvent<HTMLButtonElement>) => {
    if(!e!.currentTarget!.form!.reportValidity()) {
      return;
    }
    this.createCompetition();
  }
  
  componentDidUpdate(prevProps:IProps):void {
  }
  
  componentDidMount():void {
    let user = firebase.auth().currentUser
    if(!user) {
      if(!this.state.update) {
        let current = window.location.pathname;
        history.push(`/login?next=${current}`);
      }
    } else {
      user.getIdTokenResult()
      .then((idTokenResult) => {
         this.setState({user:user, isAdmin:!!idTokenResult.claims.admin})
       });
    }
  }
  
  onLocationSelected=(title: string, lat?: number, long?: number, place_id?: string):void =>{
    let {competition} = this.state;

    if(competition) {
      competition.location = title;
      competition.place_id = place_id;
      if(lat && long) {
        var p = {latitude:lat, longitude:long};
        competition.geopoint = p;// new GeoPoint(lat, long);
      }
    }

  };

  async createCompetition(): Promise<void> {
    
    const state:IState = this.state;
    const now:Date = firestore.Timestamp.now().toDate();
    const ref:firestore.CollectionReference  = this.props.firestore.collection(`competitions`);
    const {params} = this.props.match;
    
    if(!(state.competition)) {
      return;
    }
    
    let c:ICompetition = Object.assign(state.competition);
    c.modified = now;
    c.firstLine = Number(c.firstLine);

    let manager:ICompetitionManager = new Managers[c.pairingSystem]();
    c.rankings = manager.defaultRankings;

    if(state.update) {
      await ref.doc(params.competitionId).set(Object.assign({}, c));
    } else {
      c.created = now;
      c.owner = firebase.auth().currentUser!.uid;
      if(this.props.organiser) {
        c.organiser =  this.props.firestore.doc(`/organisers/${this.props.organiser.id}`);
      }
      await ref.add(Object.assign({}, c));
    }

    history.goBack();
  }

  createDocs = async() => {
    const {competition, rounds, players} = this.props;

    let docs: [string, any[]][] = [];
    if(competition) {
      var manager = competition.getManager(rounds, players);
      let renderer:IRenderer = manager.createRenderer(competition, 0);

      docs.push(["Players list", renderer.createPlayersPdf()]);
      for(let r:number = 1; r <= Object.values(rounds).length; r++) {
        renderer = manager.createRenderer(competition, r);
        docs.push([`Pairings of round ${r}`, renderer.createPairingsPdf()]);
        docs.push([`Standings after round ${r}`, renderer.createStandingsPdf()]);
      }
      return docs;
    }
    return [];
  }

  downloadPdf = async () => {
    const {competition} = this.props;
    if(competition && competition.date) {
      let docs: [string, any[]][] = await this.createDocs();    
      return (await new PdfGenerator().createAll( competition, docs)).download(`${competition.date.toLocaleDateString("lt-LT")} - ${competition.title}.pdf`);
    }
  }

   printPdf = async () => {
    const {competition} = this.props;
    if(competition) {
      let docs: [string, any[]][] = await this.createDocs();
      var all = await new PdfGenerator().createAll( competition, docs);
      return all.print();
    }
  }
/*
  //http://api.ipstack.com/check?access_key=57c025a052b2333b9959b634881660dc&format=1
  async searchAddressOSM(event: object, value: string, reason: string) {
    console.log("searchAddress", event, value, reason);
    let response = await fetch("//photon.komoot.io/api/?limit=10&lat=54.8218994140625&lon=23.87299919128418&q=" + value);
    var data = await response.json();
    console.log("data", data);
    var list = data.features.map((f:any)=>`${f.properties.name}`);
    this.setState({options:list});
  }
*/



  render(): ReactNode {
    const {auth, organiser} = this.props;
    const {errors, canContinue, competition, update} = this.state;
    const readOnly:boolean = !(this.canEdit() || this.isNew());

    if(!(auth && isLoaded(auth) && competition) || organiser === undefined) {
      return (<div>Loading ...</div>);
    }

    if(!firebase.auth().currentUser && !this.state.update) {
      return (<>To create competition your must login</>);
    }
    if(!organiser && auth && competition.owner === auth.uid) {
      return (
        <Redirect push to={{pathname:"/organiser", state:{goBack:true} }} />);
    }
    
    let columnSwithes:ReactNode = Object.keys(Columns).map(key => (
      <FormControlLabel key={`column.${key}`}
          control={
            <Switch
              key={`column.${key}`}
              id={`column.${key}`}
              name = {`column.${key}`}
              checked={competition.columns.includes(key)}
              onChange={this.onChange}
              value={key}
              color="primary"
            />}
        label={Columns[key].name}
        />
    ));

    let competitionType:ReactNode[] = Object.keys(Managers).map(key => (
      <FormControlLabel key={key} value={key} control={<Radio required color="primary"/>} label={key} />
    ));

    let hidden:string = readOnly?" hidden":"";

    console.log("readonly", readOnly, this.canEdit(), this.isNew());

    return (<>
        

      <Paper style={{ padding: 16 }}>
        <Box display="flex" >
          <Box flexGrow={1}>
            <Hidden smDown>
              <Typography variant="h5">
                  {this.canEdit() ? "Update competition":
                      this.isNew() ? "Create competition"
                      :"Competition details"}
              </Typography>
            </Hidden>
          </Box>
          <Box>
            <IconButton  onClick={this.downloadPdf} ><DownloadIcon /></IconButton>
            <IconButton  onClick={this.printPdf} ><PrintIcon /></IconButton>
          </Box>
        </Box>
        <Flags authorizedFlags={['adminOnly']}>
        </Flags>
        <Divider />
        <form >

          <div className="flex-table details">
            <TextField className="details title"
              required fullWidth
              error={errors.title===true}
              id="title"
              label="Title"
              name = "title"
              value={competition.title||""}
              onChange={this.onChange}
              onBlur={this.onBlur}
              margin="normal"
              style={{ margin: 8 }}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
                }}
            />
            <div style={{display: "grid", gridTemplateColumns: "auto auto"}}>
            <TextField required
                    error={errors.date===true}
                    id="date"
                    name="date"
                    label="Date"
                    type="date"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={competition.date?moment(competition.date).format("YYYY-MM-DD"):""}
                    style={{ margin: 8 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline:readOnly,
                      readOnly: readOnly
                    }}
                  />

                  < TextField
                    error={errors.start===true}
                    id="start"
                    name="start"
                    label="Start"
                    type="time"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={competition.start}
                    style={{ margin: 8 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline:readOnly,
                      readOnly: readOnly
                    }}
                  />      
            </div>
            {/* <TextField
              id="location"
              name="location"
              label="Location"
              style={{ margin: 8 }}
              value={competition.location||""}
              onChange={this.onChange}
              onBlur={this.onBlur}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
              }}
              margin="normal"
            /> */}
            <LocationInput label="Location" 
                            title={competition.location} 
                          lat={competition.geopoint?.latitude} 
                          long={competition.geopoint?.longitude} 
                          place_id={competition?.place_id} 
                          onSelected={this.onLocationSelected}
                          InputProps={{
                            disableUnderline:readOnly,
                            readOnly: readOnly
                          }}/>

            <TextField
              id="chiefArbiter"
              name="chiefArbiter"
              label="Chief Arbiter"
              style={{ margin: 8 }}
              value={competition.chiefArbiter||""}
              onChange={this.onChange}
              onBlur={this.onBlur}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
              }}
              margin="normal"
            />

            <TextField
              id="arbiters"
              name="arbiters"
              label="Arbiter(s)"
              style={{ margin: 8 }}
              value={competition.arbiters||""}
              onChange={this.onChange}
              onBlur={this.onBlur}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
              }}
              margin="normal"
            />

            <TextField
              id="director"
              name="director"
              label="Director"
              style={{ margin: 8 }}
              value={competition.director||""}
              onChange={this.onChange}
              onBlur={this.onBlur}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
              }}
              margin="normal"
            />

          <div className={"flex-cell first"}>
            {readOnly 
            &&
            <TextField
            id="pairingSystam"
            label="Pairing System"
            style={{ margin: 8 }}
            value={competition.pairingSystem||""}
            InputProps={{
              disableUnderline:readOnly,
              readOnly: readOnly
            }}
            margin="normal"
          />
            ||<FormGroup >
              <FormControl component="fieldset">
                <FormLabel component="legend">Pairing system *</FormLabel>
                {/* @ts-ignore onChagen definitions should be updated*/}
                  <RadioGroup
                    aria-label="Pairing system"
                    name="pairingSystem"
                    value={competition.pairingSystem||""}
                    onChange={(e)=>this.onChange(e as any)}
                    onBlur={this.onBlur}
                  >
                    {competitionType}
                  </RadioGroup>
              </FormControl>
            </FormGroup>}

            <TextField required className={""+hidden}
              error={errors.firstLine===true}
              id="firstLine"
              name="firstLine"
              label="First Line"
              value={competition.firstLine}
              onChange={this.onChange}
              onBlur={this.onBlur}
              type="number"
              style={{ margin: 8 }}
              inputProps={{
                  min:"1"
              }}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
              }}
              margin="normal"/>
              
            <FormControlLabel  className={""+hidden}
              control={ <Checkbox checked={competition.randomLine} onChange={this.onChange} name="randomLine" 
                            color="primary" style={{ margin: 8 }}/>
                      }
              label="Randomize Lines"
            />
          </div>

          <FormControl component="fieldset" className={""+hidden}>
              <FormLabel component="legend">Columns to use</FormLabel>
              {columnSwithes}
          </FormControl>
          {this.state.competition?.pairingSystem=="SwissSystem" &&
          <div className={""}>
            <TextField required 
              error={errors.rounds===true}
              id="rounds"
              name="rounds"
              label="Rounds"
              value={competition.rounds||""}
              onChange={this.onChange}
              onBlur={this.onBlur}
              type="number"
              style={{ margin: 8 }}
              inputProps={{
                  min:"1"
              }}
              InputProps={{
                disableUnderline:readOnly,
                readOnly: readOnly
              }}
              margin="normal"
            />
          </div>
          }
            {!readOnly &&
            <div className="flex-cell first">
              <Button  variant="contained" color="primary" onClick={this.onSubmitButton}>
                Save
              </Button>
            </div>
            }
          </div>
        </form>
      </Paper>
    </>
    );
  }
}

export default compose<IProps, IProps>(
  connect<any, IProps, IProps>(({firebase: {auth}, firestore: {ordered, data} }:any, props:IProps) => {

    let competition:ICompetition|undefined;
    let organiser: Organiser|undefined|null = undefined;

    if(auth && auth.isLoaded) {
      if(ordered.organiser ) {
        organiser = ordered.organiser[0]?ordered.organiser[0]:null;
      }
      if( organiser === null || organiser && (auth.uid !== organiser.owner)) {
        organiser = null;
      }
    }

    if( props.match.params.competitionId != null) {
      competition = ICompetition.fromFirestore(ordered.competition[0]);
    }

    return {
      auth,
      competition: competition,
      organiser: organiser,
      rounds: data.rounds,
      players: data.players
    };
  }),
  firestoreConnect((props: IProps) =>  {
    return props.auth && props.auth.isLoaded?
    [
        { collection: `competitions`,
          doc: props.match.params.competitionId,
          storeAs: "competition",
          subcollections:[
            {collection: "rounds"},
            {collection: "players"}
          ],
        },
       { collection: `organisers`,
          where: ["owner", "==", props.auth.uid||""],
          storeAs: "organiser"
        },
    ]:
    [];
  }),

)(Create);
