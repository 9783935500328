import {Player} from "./ICompetitionManager";
import BaseCompetitionManager from "./BaseCompetitionManager";
import {Pairing} from "../../models/Pairing";
import { ICompetition } from "../../models/Competition";
import SwissSystemRenderer from "../../render/SwissSystemRenderer";
import { IRenderer } from "../../render/BaseRenderer";


class Pair {
    round?: number;
    home?: Player;
    away?: Player;
}

export default class SwissSystem extends BaseCompetitionManager {

  allRounds: boolean = false;

  defaultRankings: string[] =
              ["WinsRanking", "BuchholzRanking", "FineBuchholzRanking", "PointsDiffRanking"];

  competition:ICompetition;
  rounds:Pairing[][];
  players:{[id:string]: Player};
  round: number;

  constructor(competition:ICompetition, rounds:Pairing[][], players:{[id:string]: Player}, round: number) {
    super();
    this.competition = competition;
    this.rounds = rounds;
    this.players = players;
    this.round = round;
  }
     
  getRoundCount(): number {
    return this.competition.rounds || 0;
  }
  createRenderer(competition: ICompetition,  round: number): IRenderer {
    return new SwissSystemRenderer(competition, this.players, this.rounds, round, -1);
  }

  roundsFromPlayers(players: number): number {
        throw new Error("Method not implemented.");
    }

  _getRandomInt(max:number):number {
    return Math.floor(Math.random() * max);
  }

  _createPairings(competition:ICompetition,rounds:Pairing[][], players: {[id:string]: Player}, round: number): Pairing[] {
      let pairings:Pairing[] = [];

      var matches:Pair[] = this.collectMatches(rounds);

      var swiss:any  = require("swiss-pairing")({
        maxPerRound: 1,
        seedMultiplier:this._getRandomInt(10000)
      });

      let clonedPlayers: Player[] = [];
      Object.keys(players).forEach(key=>{
        let p:Player = Object.assign({}, players[key]);
          if(!p.seed) {
            p.seed = p["rating"] || this._getRandomInt(10000);
          }
          p.id = key;
          clonedPlayers.push(p)
        });

      let matchups:any = swiss.getMatchups(round, clonedPlayers, matches);
      console.log(matchups);

      matchups.forEach((m:any, i:number) => {
          let pairing: Pairing = {
            key: "",
            player1: m.home,
            player2: m.away,
            show: true,
            spot: i + competition.firstLine
        };
        if(!m.home) {
          pairing.result2 = "7";
        }
        if(!m.away) {
          pairing.result1 = "7";
        }
        pairings.push(pairing);
      });

      return pairings;
    }

    standings(competition:ICompetition, rounds:Pairing[][], players: {[id:string]: Player}, afterRound:number):Player[] {

      return [];
    }

    private collectMatches(rounds:Pairing[][]):Pair[] {

        let result:Pair[] = [];

        let points:{[id:string]: number} = {};

        rounds.forEach((round, r) => {
            console.log(`Round ${round}`);
            let p:Pairing;
            for( let i:number = 1; p = (round as any)[i]; i++) {

              var w1:number = 0;
              var w2:number = 0;

              let p1:number = p.result1?Number(p.result1):0;
              let p2:number = p.result2?Number(p.result2):0;

              if(p1>p2) {
                w1 ++;
              } else if(p2>p1) {
                w2 ++;
              } else {
                w1 += 0.5;
                w2 += 0.5;
              }
              if(p.player1) {
                points[p.player1] = (points[p.player1] || 0)+ w1;
              }
              if(p.player2) {
                points[p.player2] = (points[p.player2] || 0) + w2;
              }

              const pair:Pair = {
                round: r,
                home: { id:p.player1, points:w1},
                away: { id:p.player2, points:w2}
              };
              result.push(pair);
            }

            console.log(result);
          });

        return result;
    }
}