import urljoin from "url-join";
import { connect } from "react-redux";
import { compose } from "recompose";

import { firestore } from "firebase";
import React, { Component, ReactNode } from "react";
import {Paper, Table, TableHead,TableRow, TableBody, TableCell, Grid, Typography, Avatar, Button, Hidden, Box } from "@material-ui/core";
import { firestoreConnect } from "react-redux-firebase";
import { history } from "../../redux/HistoryRouter";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";

import {ICompetition} from "../../models/Competition";
import { Organiser } from "../../models/Organiser";

interface IState {
  logoImages:Map<string, string|null>;
}
interface IProps extends IComponentProps {
  competitions: ICompetition[];
}

class Competitions extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      logoImages: new Map<string, string|null>()
    };
  }

  getLogo(competition:ICompetition):string|undefined {
    let {logoImages} = this.state;
    let logo:string|null|undefined = undefined;

    if(competition.organiser) {
      let organiserId:string = competition.organiser.id;

      logo = logoImages.get(organiserId);
      if(logo === undefined) {
        logo = null;

        let organiser: Organiser|undefined;
        if(competition.organiser) {
          competition.organiser.get().then((snapshot:firestore.DocumentSnapshot) => {
            organiser = snapshot.data() as Organiser;
            if(organiser) {
              logo = organiser.logoImage || null;
            }
            logoImages.set(organiserId, logo || null);
            this.setState({logoImages});
          });
        }
      }
    }
    return logo || undefined;
  }

  onCompetitionClick = (e:any) => {
    console.log(e);
    history.push(e);
    return false;
  }

  render():ReactNode {
    const { match, competitions } = this.props;
    if(!competitions) {return null;}

    const cellStyle:Object = {
        padding: "16px",
    };

    return (
      <>
        <Box p={1}>
          <Grid
                justify="space-between"
                container
                spacing={2}
              >
            <Grid item>
              <Typography variant="h5">
                    Competitions
              </Typography>
            </Grid>

            <Grid item>
              <Button onClick={()=>history.push("/competitions/create")} color="primary" variant="contained">
                <AddIcon /> 
                <Hidden smDown>Create competition</Hidden>
              </Button >
            </Grid>
          </Grid>
        </Box>
        <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyle}></TableCell>
                  <TableCell style={cellStyle}>Title</TableCell>
                  <TableCell style={cellStyle}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {competitions.map((competition:ICompetition) => {
                    let logo:string|undefined = competition.logo || this.getLogo(competition);
                    return (
                      <TableRow key={`${competition.id}`} onClick={
                        ()=>this.onCompetitionClick(urljoin(match.url, `${competition.id}`, "players"))}>
                        <TableCell style={cellStyle}>
                          <Avatar component="span"
                                style={{width:32, height:32, borderRadius:0, backgroundColor:"transparent"}}
                                imgProps={{style: {objectFit:"contain"} }}
                                src={logo}
                                title="Logo"
                              />
                        </TableCell>

                        <TableCell style={cellStyle}>
                            {competition.title}
                        </TableCell>
                        <TableCell style={cellStyle}>{competition.date?
                          moment(competition.date).format("YYYY-MM-DD"):""}</TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
      </Paper>
      </>
    );
  }
}

export default compose<IProps, IProps>(

  connect<any, IProps, IProps>(({ firestore, firebase }:any, props:IProps):any => {
    let competitions:ICompetition[] = [];
    if(!firestore.data.competitions) {
      return {};
    }
    firestore.ordered.competitions.forEach((competition:any) => {
      competitions.push(ICompetition.fromFirestore(competition));
    });

    return {
      competitions: Object.values(competitions)
    };
  }),
  firestoreConnect((props: IProps) => {
    return [
      {
        collection: "competitions", 
        orderBy: [["date", "desc"]],
        storeAs: "competitions"
      }
    ];
  })
)(Competitions);


