import React, { Component, ReactNode } from "react";
import { MenuItem,IconButton }  from "@material-ui/core";
import {CloudDownload as DownloadIcon, Print as PrintIcon} from "@material-ui/icons";
import memoize from "memoize-one";

import { connect } from "react-redux";
import { compose } from "recompose";
import { firestoreConnect, isLoaded} from "react-redux-firebase";
import pdfMake from "pdfmake/build/pdfmake";
import PdfGenerator from "../../common/PdfGenerator";
import { ICompetition } from "../../models/Competition";
import { Pairing } from "../../models/Pairing";
import { IRenderer } from "../../render/BaseRenderer";
import { Player } from "../../algorithms/managers/ICompetitionManager";

interface IState {
  roundId?: number;
  tab?: number;
  anchorEl?:any;
  ranks?:Map<string, number>[];
  ordered?:string[]|null;
  renderer?:IRenderer;
}

interface IInputProps {
  menu?: boolean;
  toolbar?: boolean;
  roundId?: number;
  competitionId?: string;
}

interface IProps extends IComponentProps, IInputProps {
  auth: any;
  competition: ICompetition;
  players: {[id:string]: Player};
  rounds: Pairing[][];
}

class Standings extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let roundId:number = Number(props.roundId || 1);
    this.state = {roundId};
  }

  renderer = memoize(
    (props:IProps) => 
       props.competition.getManager(props.rounds, props.players).createRenderer(props.competition, props.roundId||0)
  );


  downloadPdf = async (e:React.MouseEvent<HTMLLIElement|HTMLAnchorElement|HTMLButtonElement, MouseEvent>) => {
    (await this.createPdf()).open();
  }

   printPdf = async (e:React.MouseEvent<HTMLLIElement|HTMLAnchorElement|HTMLButtonElement, MouseEvent>) => {
    (await this.createPdf()).print();
  }

  async createPdf(): Promise<pdfMake.TCreatedPdf> {
    const { roundId } = this.props.match.params;
    const { competition} = this.props;

    let content:any = this.renderer? this.renderer(this.props).createStandingsPdf() : [];
    return new PdfGenerator().create(`Standings after round ${roundId}`, competition, content);
  }

  render(): ReactNode {
    console.log(this.props.match);

    if( this.props.menu) {
      return  (
      <>
        <MenuItem onClick={this.downloadPdf}>Download Pdf</MenuItem>
        <MenuItem onClick={this.printPdf}>Print</MenuItem>
      </>
      );
    } else if( this.props.toolbar) {
      return (
        <>
          <IconButton  onClick={this.downloadPdf} ><DownloadIcon /></IconButton>
          <IconButton  onClick={this.printPdf} ><PrintIcon /></IconButton>
        </>
      );
    } else {
      return this.renderer(this.props) ? this.renderer(this.props).createStandingsHtml() : null;
    }
  }
}

export default compose<IProps, IInputProps>(
    firestoreConnect((props:IProps) =>  [
            { collection: `competitions`,
              doc: props.competitionId,
              subcollections:[
                {collection: "rounds"},
                {collection: "players"}
              ],
              storeAs: "competition"
            }]),
    connect(({ firestore: { data, ordered } }: any, props) => ({
      competition: ICompetition.fromFirestore(ordered.competition[0]),
      rounds: data.rounds,
      players: data.players
    }))
  )(Standings);