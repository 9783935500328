import React, { Component, ReactNode } from "react";

import {MenuItem, IconButton, OutlinedInput} from "@material-ui/core";
import {CloudDownload as DownloadIcon, Print as PrintIcon} from "@material-ui/icons";
import { connect } from "react-redux";
import { compose} from "recompose";
import { firestoreConnect, isLoaded} from "react-redux-firebase";
import firebase from "firebase/app";
import memoize, { EqualityFn } from "memoize-one";

import PdfGenerator from "../../common/PdfGenerator";
import { ICompetition } from "../../models/Competition";
import { Pairing, Pairings } from "../../models/Pairing";
import {IRenderer} from "../../render/BaseRenderer";
import { Player } from "../../algorithms/managers/ICompetitionManager";

interface IState {
  roundId?: number;
  wins?: Map<string, number>|null;
}

interface IInputProps {
  menu?: boolean;
  toolbar?: boolean;
  roundId?: number;
  competitionId?: string;
}

interface IProps extends IComponentProps,IInputProps {
  competition: ICompetition;
  players:{[id:string]: Player};
  allPairings:Pairing[][];
  currentPairings:Pairings;
  match:any;
}

var deepEqual = require('deep-equal');

function areInputsEqual(
  newInputs: readonly unknown[],
  lastInputs: readonly unknown[],
): boolean {

  let result =  deepEqual(newInputs, lastInputs);

  console.log("memoize 1", result, newInputs, lastInputs);
  return result;
}

class Table extends Component<IProps, IState> {
  constructor(props:IProps) {
    super(props);

    let roundId:number = props.roundId || 1;
    this.state = {roundId};
  }

  renderer = memoize(
    (props:IProps) => {
      console.log("memoize create", props);
       return props.competition.getManager(props.allPairings, props.players).createRenderer(props.competition, props.roundId||0)
    },
    areInputsEqual
  );
  componentDidUpdate(prevProps:IProps):void {
    console.log("Table componentDidUpdate", this.state, this.props);

  }

  canEdit():boolean {
    var user:firebase.User|null = firebase.auth().currentUser;
    return this.props.currentPairings && (this.props.currentPairings.owner === (user && user.uid));
  }

  downloadPdf = async () => {
    (await this.createPdf()).open();
  }

   printPdf = async () => {
    (await this.createPdf()).print();
  }

  createPdf = async () => {
    const {competition} = this.props;
    const {roundId} = this.state;

    let content:any[] = this.renderer? this.renderer(this.props).createTablePdf():[];
    return new PdfGenerator().create(`Table after round ${roundId}`, competition, content, this.renderer(this.props).landscapeTable());
  }

  render():ReactNode {

    if(this.props.menu) {
      return (
        <>
          <MenuItem onClick={this.downloadPdf}>Download Pdf</MenuItem>
          <MenuItem onClick={this.printPdf}>Print</MenuItem>
        </>
      );
    } else if(this.props.toolbar) {
      return (
        <>
          <IconButton  onClick={this.downloadPdf} ><DownloadIcon /></IconButton>
          <IconButton  onClick={this.printPdf} ><PrintIcon /></IconButton>
        </>
        );
    } else {
      let {competition, players, allPairings} = this.props;

      if(!isLoaded(competition, players, allPairings)) {
        return null;
      }
      return this.renderer? this.renderer(this.props).createTableHtml():null;
    }
  }
}

export default compose<IProps, IProps>(
  firestoreConnect((props:IProps) =>  [
    { collection: `competitions`,
      doc: props.competitionId,
      subcollections:[
        {collection: "rounds", doc: `${props.roundId}`},
        {collection: "players"}
      ],
      storeAs: "competition"
    }
  ]),
  connect(({ firestore: { data, ordered } }: any, props:IProps) => {

    let roundPairings:Pairings|null = data.rounds&&props.roundId?data.rounds[Number(props.roundId)]:null;

    return {
      allPairings:data.rounds,
      players: data.players,
      currentPairings: roundPairings,
      competition: ICompetition.fromFirestore( ordered.competition[0])
    };
  })
)(Table);