import urljoin from "url-join";
import React, { Component, ReactNode } from "react";
import PlayerRow from "./PlayerRow";
import { Paper, TableCell, Button, MenuItem, IconButton, Menu, Box, Hidden, Divider, Typography } from "@material-ui/core";

import { history } from "../../redux/HistoryRouter";
import PdfGenerator from "../../common/PdfGenerator";
import {connect} from "react-redux";
import { compose, withHandlers  } from "recompose";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import firebase from "firebase/app";
import {CloudDownload as DownloadIcon, Print as PrintIcon, MoreVert as MoreVertIcon} from "@material-ui/icons";
import { Columns } from "../../common/Columns";
import { CollectionReference } from "@firebase/firestore-types";
import { TCreatedPdf } from "pdfmake/build/pdfmake";
import { Pairing, Player } from "../../models/Pairing";
import { ICompetition } from "../../models/Competition";
import { IRenderer } from "../../render/BaseRenderer";
import { string } from "prop-types";

const csv:any = require("csvtojson");

interface IState {
  anchorEl?:Element;
  renderer?:IRenderer;
}



interface IInputProps {
}

interface IProps extends IComponentProps, IInputProps {
  competition: ICompetition;
  players: {[id: string]: Player};
  rounds: Pairing[][]
}


class Players extends Component<IProps, IState> {

  constructor(props:IProps) {
    super(props);
    this.state = {
    };
  }

  componentDidUpdate(prevProps:IProps):void {
    let {competition, players} = this.props;
    let roundId: number = Number(this.props.match.params.roundId);

    if (this.props.competition !== prevProps.competition ||
      this.props.players !== prevProps.players
      ) {
        if(isLoaded(competition, players)) {
          let renderer:IRenderer = competition.getManager([], players).createRenderer(competition, roundId);
          this.setState({renderer:renderer});
        }
    }
  }


  canEdit(): boolean {
    var user:firebase.User|null = firebase.auth().currentUser;
    return this.props.competition.owner === (user && user.uid);
  }

  onImportCsv = async (e:React.ChangeEvent<HTMLInputElement>) => {
    let target:HTMLInputElement = e.target;
    let inputTarget:HTMLInputElement = target;
    if(inputTarget && inputTarget.files) {
      let file:any = inputTarget.files[0];
      await this.importCsvFileAsync(file);
    }
  }

  importCsvFileAsync(file: any):Promise<void> {
    return new Promise((resolve, reject) => {
      let reader:FileReader = new FileReader();

      reader.onload = () => {
        let text:string|ArrayBuffer|null = reader.result;

        let user:firebase.User|null = firebase.auth().currentUser;
        csv()
          .fromString(text)
          .subscribe((row: any)=> {
            try {
              const {competitionId} = this.props.match.params;
              const {firestore} = this.props;
              const ref:CollectionReference = firestore.collection(`competitions/${competitionId}/players/`);
              ref.add({
                name: row.name,
                club: row.club || "",
                country: row.country || "",
                city: row.city || "",
                owner: user && user.uid
              }).then(() => {
                resolve();
              }).catch((error:Error) => {
                console.error("Error updating document: ", error);
                reject();
              });

            } catch {
              reject();
            }
          });
        resolve();
      };

      reader.onerror = reject;
      reader.readAsText(file);
    });
  }

  showMoreMenu = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target:Element = e.currentTarget;
    this.setState({anchorEl:target});
  }

  closeMoreMenu = () => {
    this.setState({anchorEl:undefined});
  }

  downloadPdf = async () => {
    (await this.createPdf()).open();
  }

   printPdf = async () => {
    (await this.createPdf()).print();
  }

  async createPdf():Promise<TCreatedPdf> {
    let {competition} = this.props;
    let {renderer} = this.state;

    let content:any[] = renderer? renderer.createPlayersPdf() : [];

    return new PdfGenerator().create(`Players list`, competition, content);
  }

  wasPaired(playerId: string, rounds:Pairing[][]):boolean{
    if(rounds) {
      for(let roundPairings of Object.values(rounds)) {
        for(let pairing of Object.values(roundPairings)) {
          if(pairing.player1 === playerId || pairing.player2 === playerId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  render():ReactNode {
    const { players, competition, rounds } = this.props;
    const {anchorEl} = this.state;
    let columns:string[] = competition && competition.columns || [];

    const {competitionId} = this.props.match.params;

    let self = this;

    var players2:ReactNode[] = Object.values(players).map( function(player:Player, i:number):ReactNode {
      var user:firebase.User|null = firebase.auth().currentUser;
      const canEdit:boolean = !player || player.owner === (user && user.uid);
      let canDelete: boolean = isLoaded(rounds) && !self.wasPaired(player.id, rounds);
      return (<PlayerRow player={player} id={player.id}  key={player.id} num={i} competitionId={competitionId}
        columns={columns}
        readOnly={!canEdit} canDelete={canDelete}/>);
    });

    let columnTitles:ReactNode[] = columns.map((c, i)=> {
                                return (<div key={c} style={{ }} className={"flex-row flex-header details"}>{Columns[c].name}</div>);
                              });

    let detailsTemplateColumns:string = columns.length > 0 ? ` repeat(${columns.length}, 1fr)`:"";

    return (
      <Paper>
        <Box display="flex" >
          <Box flexGrow={1}>
            <Hidden smDown>
              <Typography variant="h5">Players</Typography>
            </Hidden>
          </Box>
          <Box>
            <IconButton  onClick={this.downloadPdf} ><DownloadIcon /></IconButton>
            <IconButton  onClick={this.printPdf} ><PrintIcon /></IconButton>
            <IconButton  onClick={this.showMoreMenu} ><MoreVertIcon /></IconButton>
          </Box>
        </Box>
        <Menu id="more-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMoreMenu}>
          {this.canEdit() && [
            <MenuItem key="import-csv-button">
              <input hidden name="playerList" accept=".csv, .txt"
                        id="raised-button-file"type="file" onChange={this.onImportCsv}/>
              <label htmlFor="raised-button-file">
                Import from csv
              </label>
            </MenuItem>,
            <MenuItem key="import-from-competition" onClick={() => history.push("import/players")}>
              Import from competition
              </MenuItem>
          ]}
        </Menu>
        <Divider />

        <div className="flex-table players" style={{ gridTemplateColumns:`min-content min-content 2fr ` + detailsTemplateColumns}}>
          {this.canEdit() &&
            <div key="static-delete" className="flex-row button">
            </div>
          }
          <div className="flex-row flex-header num">#</div>
          <div className="flex-row flex-header first">Name</div>
          {columnTitles}

          {players2}
          
          {this.canEdit() &&
          <PlayerRow key="new" id={null} num={null} player={{name:""}} competitionId={competitionId}
                              columns={columns}/>
          }

        </div>
      </Paper>
    );
  }
}


export default
compose <IProps, IInputProps>(
  firestoreConnect((props:IProps) =>  [

        { collection: `competitions`,
          doc: props.match.params.competitionId,
          storeAs: "competition"
        },
        {
          collection: `competitions`,
          doc: props.match.params.competitionId,
          subcollections:[
            {collection: "players"},
            {collection: "rounds"}
          ],
          storeAs:"players",
        },
      ]),
  connect(({ firestore: { data, ordered } }:any, props) => {
    let players:Player[] = ordered.players &&
      (Object.values(ordered.players) as Player[]).sort((p1:Player, p2:Player)=> {
        return p1.name.localeCompare(p2.name);
      }) || [];

    return {
      players: players,
      rounds: data.rounds,
      competition: ordered.competition?ICompetition.fromFirestore(ordered.competition[0]):null
    };
  })
)(Players);
